import React from "react";
import { useTranslation } from "react-i18next";
import FacebookIframe from "./FacebookIframe";
import { useSpring, animated, useScroll, useInView, Transition } from '@react-spring/web';
import { getFacebookUrl } from "../helpers/Api/apiHelper";

function ShowComponentOnClick() {
    const [showComponent, setShowComponent] = React.useState(1);
    const {t} = useTranslation();
    const staticFbUrl = process.env.REACT_APP_SHANDE_FACEBOOK_SUPPLIES_URL;
    const [url, setUrl] = React.useState(staticFbUrl);

    React.useEffect(() => {
        const getMyUrl = async () => {
            const response = await getFacebookUrl();
            if(response) {
                console.log(response);
                if(response?.success) {
                    setUrl(response?.success?.url ?? staticFbUrl)
                }
                else {
                    setUrl(staticFbUrl)
                }
            }
            else {
                setUrl(staticFbUrl)
            }
        }

        getMyUrl();
    }, []);
    const displayComponent = (component = 1) => {
        setShowComponent(component);
        // select all btn_component and remove selected class
        document.querySelectorAll(".btn_component").forEach((el) => {
            el.classList.remove("selected");
        })
        document.getElementById(`btn_component${component}`).classList.add("selected");
    }

    const animatedDisplay1 = useSpring({
        opacity: showComponent == 1 ? 1 : 0,
        transform: showComponent == 1 ? "translateY(0px)" : "translateY(-50px)",
        config: { duration: 1000 }
    })

    const animatedDisplay2 = useSpring({
        opacity: showComponent == 2 ? 1 : 0,
        transform: showComponent == 2 ? "translateY(0px)" : "translateY(-50px)",
        config: { duration: 1000 }
    })

    const animatedDisplay3 = useSpring({
        opacity: showComponent == 3 ? 1 : 0,
        transform: showComponent == 3 ? "translateY(0px)" : "translateY(-50px)",
        config: { duration: 1000 }
    })
    return (
        <div className="container">
            <div className="show-component-container">
                <div className="row text-center">
                    <div className="col-sm-4">
                        <button id="btn_component1" className="btn_component selected" onClick={()=>displayComponent(1)}>{t("bankDetails")}</button>
                    </div>
                    <div className="col-sm-4">
                        <button id="btn_component2" className="btn_component" onClick={()=>displayComponent(2)}>{t("donationSupplies")}</button>
                    </div>
                    <div className="col-sm-4">
                        <button id="btn_component3" className="btn_component" onClick={()=>displayComponent(3)}>{t("duitNow")}</button>
                    </div>
                </div>
            </div>
            <div className="text-center pt-4">
                {
                    showComponent == 1 && 
                    <animated.div style={animatedDisplay1}>
                    <img className="mw-100 mh-600" src="assets/img/donation_details/cimb.png" alt="Bank Details" />
                    </animated.div>
                }

                {
                    showComponent == 2 && 
                    <animated.div style={animatedDisplay2}>
                        <FacebookIframe
                        url = {url}
                        />
                    </animated.div>
                }
                {
                    showComponent == 3 && 
                    <animated.div style={animatedDisplay3}>
                    <img className="mw-100 mh-600" src="assets/img/donation_details/duitnow_QR.jpg" alt="Duit Now" />
                    </animated.div>
                }
            </div>
        </div>
    )
}
export default ShowComponentOnClick;