import { useTranslation } from "react-i18next";
import { getLanguage, getLanguageName, setLanguage } from "../helpers/storageHelper";

function Header() {

    const { i18n, t } = useTranslation();

    function handleChangeLanguage(event) {
        if(getLanguage() == 1) {
            i18n.changeLanguage("2");
            setLanguage(2);
        }
        else if(getLanguage() == 2) {
            i18n.changeLanguage("1");
            setLanguage(1);
        }
        else {
            i18n.changeLanguage("1");
            setLanguage(1);
        }
        // window.location.reload();
    }

    const languageOptions = [
        { id: 1, name: '中文' },
        { id: 2, name: 'English' },
        // Add more languages as needed
      ];
    return (
        <nav className="navbar navbar-dark navbar-expand-lg sticky-top portfolio-navbar gradient-pink">
            <div className="container">
                <a className="navbar-brand logo" href="/">
                    <img src="assets/img/80_sdlogo.png" alt="logo" className="logo-icon" />
                    {t("shande")}
                </a>
                <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navbarNav">
                    <span className="visually-hidden">Toggle navigation</span><span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item"><a className="nav-link active" href="/">{t("home")}</a></li>
                        <li className="nav-item"><a className="nav-link" href="/news">{t("news")}</a></li>
                        <li className="nav-item dropdown">
                            <a className="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown" href="#">{t("supportUs")}</a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="/donationDetails">{t("donationDetails")}</a>
                                <a className="dropdown-item" href="/sponsorshipPlan">{t("sponsorshipPlan")}</a>
                                <a className="dropdown-item" href="/lovelyMealPlan">{t("lovelyMealPlan")}</a>
                                <a className="dropdown-item" href="/charitySales">{t("charitySales")}</a>
                            </div>
                        </li>
                        <li className="nav-item"><a className="nav-link" href="/events">{t("events")}</a></li>
                        <li className="nav-item dropdown">
                            <a className="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown" href="#">{t("aboutShande")}</a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="/aboutUs">{t("aboutUs")}</a>
                                <a className="dropdown-item" href="/ourOrganization">{t("ourOrganization")}</a>
                                <a className="dropdown-item" href="/career">{t("career")}</a>
                            </div>
                        </li>
                        <li className="nav-item"><a className="nav-link" href="/contactUs">{t("contactUs")}</a></li>
                        <li className="nav-item"><a className="nav-link" href="/faq">{t("faq")}</a></li>
                        
                        <div className="d-flex align-items-center">
                            <i className="ion-earth text-white me-2 me-md-0"></i>
                            {/* <select className="nav-item nav-link custom-select" defaultValue={getLanguage() ?? 1} onChange={handleChangeLanguage}>
                            {languageOptions.map((language) => (
                                <option key={language.id} value={language.id}>{language.name}</option>
                            ))}
                            </select> */}
                            <button type = "button" onClick={handleChangeLanguage} className="nav-item nav-link custom-select">{getLanguageName()}</button>
                        </div>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default Header;