import { useTranslation } from "react-i18next";
import { calculateAge, genderFullName, getRandomStaffData, replaceExceptFirst } from "../helpers/dataHelper";
import { getLanguage } from "../helpers/storageHelper";

function ImageAndDetails(props) {
    const data = props?.data;
    const language = getLanguage();
    const {t} = useTranslation();
    const redirectToRandomWhatsApp = (shandeId) => {
        const randomContact = getRandomStaffData("Contact", "Marketing");
        const message = language == 1 ? `你好，我想了解助养计划 (${shandeId})` : `Hi, i would like to know further details about (${shandeId})`;
        const whatsappURL = `https://api.whatsapp.com/send?phone=6${randomContact}&text=${message}`;
        window.location.href = whatsappURL;
    }
    return (
        <section class="portfolio-block projects-cards pt-4">
            <div class="container">
                <div class="row">
                    {
                        data && data.map(item=>(
                            <div class="col-md-6 col-lg-3 card-container g-3">
                                <div class="card border-0">
                                    <div class="text-center">
                                    <a>
                                        <img class="card-img-top scale-on-hover portrait-img" 
                                        src={item?.pictureLocation} alt={item?.shandeId} />
                                    </a>
                                    </div>
                                    <div class="card-body pt-3 pb-0">
                                        <h6>{item?.shandeId}</h6>
                                        <p class="text-muted card-text">
                                            {t("name")}: {replaceExceptFirst(item?.lastName)}
                                            <br/>
                                            {t("gender")}: {genderFullName(item?.gender)}
                                            <br/>
                                            {t("age")}: {calculateAge(item?.dob)} {t("yearsOld")}

                                        </p>
                                        <button class="btn btn-primary" onClick={()=>redirectToRandomWhatsApp(item?.shandeId)}>{t("clickToKnowMe")} <i class="ion-heart"/></button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}
export default ImageAndDetails;