function ImageAndDetails2(props) {
    // pass in data array object
    const data = props?.data;
    return (
        <section className="portfolio-block projects-cards pt-4">
            <div className="container">
                <div className="row">
                    {
                        data && data.map(item=>(
                            <div className="col-md-6 col-lg-3">
                                <div className="card border-0 card-container p-3 mb-3">
                                    <div className="text-center">
                                    <a>
                                        <img className="card-img-top scale-on-hover portrait-img" 
                                        src={item?.img} alt="Card Image" />
                                    </a>
                                    </div>
                                    <div className="card-body pt-3 pb-0">
                                        <h6>{item?.title}</h6>
                                        <p className="text-muted card-text">
                                            {item?.name}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}
export default ImageAndDetails2;