import React from 'react';

function MultilineContent (content) {
    if(content) {
        const hasLineBreaks = content.includes('\n');
        const lines = hasLineBreaks ? content.split('\n') : null;
    
        if(lines) {
          return (
            <div>
              {lines.map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
          );
        }
        else {
            return <div><p>{content}</p></div>
        }
    }
};

export default MultilineContent;
