import { useTranslation } from "react-i18next";
import TitleHeading from "./TitleHeading";

function TwoTitleAndDescription() {
    const {t} = useTranslation();
    return (
        <div className="pt-5 mt-3">
            <div className="row">
                <div className="col-md-6 p-2">
                    <TitleHeading
                    title = {t("ourTarget")}
                    className = "pb-4"
                    />
                    <p className="ps-3 pe-3">{t("ourTargetDescription")}</p>
                </div>
                <div className="col-md-6 p-2">
                    <TitleHeading
                    title = {t("ourVision")}
                    className = "pb-4"
                    />
                    <p className="ps-3 pe-3">{t("ourVisionDescription")}</p>
                </div>
            </div>
        </div>
    )
}
export default TwoTitleAndDescription;