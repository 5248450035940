import React from "react";
import { useTranslation } from "react-i18next";

function OurReviewV2() {
    const {t} = useTranslation();
    return (
        <section className="review-page">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <img className="google-image" src="assets/img/google/google-review.png" alt="google-review" />
                        <div className="mt-4">
                            <a href="https://search.google.com/local/writereview?placeid=ChIJW2RnJyht2jERPqizN1gz8EY" target="_blank" rel="noreferrer">
                            {t('reviewUs')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default OurReviewV2;