import { useTranslation } from "react-i18next";
import TitleHeading from "./TitleHeading";

function ThreeImagesInRow() {
    const photo_url = process.env.REACT_APP_SHANDE_PHOTO_URL;
    const {t} = useTranslation();
    const redirectTo = (path) => {
        window.location.href = path;
    }
    return (
        <>
        <section class="portfolio-block photography compact-grid">
            <div class="container">
                <TitleHeading 
                className = "text-center"
                title = {t("ourProgramme")}
                />
                <div class="row g-0 imagesInRow-container">
                    <div class="col-md-6 col-lg-4 item zoom-on-hover position-relative">
                        <a onClick={()=>redirectTo("/sponsorshipPlan")}>
                            <img class="img-fluid image" src={`${photo_url}child_img/sponsor.png`}  alt="sponsorshipPlan" />
                            <span class="description">
                                <span class="description-heading">{t("sponsorshipPlan")}</span>
                                <span class="description-body">{t("sponsorshipPlanShortExplanation")}</span>
                            </span>
                        </a>
                    </div>
                    <div class="col-md-6 col-lg-4 item zoom-on-hover position-relative">
                        <a onClick={()=>redirectTo("/lovelyMealPlan")}>
                            <img class="img-fluid image" src={`${photo_url}lovely_meal_plan/lovely_lanscape.jpeg`}  alt="lovelyMealPlan" />
                            <span class="description">
                                <span class="description-heading">{t("lovelyMealPlan")}</span>
                                <span class="description-body">{t("lovelyMealPlanShortExplanation")}</span>
                            </span>
                        </a>
                    </div>
                    <div class="col-md-6 col-lg-4 item zoom-on-hover position-relative">
                        <a onClick={()=>redirectTo("/charitySales")}>
                            <img class="img-fluid image" src={`${photo_url}charity_sale/sales.jpg`} alt="charitySales" />
                            <span class="description">
                                <span class="description-heading">{t("charitySales")}</span>
                                <span class="description-body">{t("charitySalesShortExplanation")}</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="portfolio-block call-to-action border-bottom">
            <div class="container">
                <div class="d-flex justify-content-center align-items-center content">
                    <h3>{t("makeDonation")}?</h3>
                    <button onClick={()=>redirectTo("/donationDetails")} class="btn btn-outline-primary btn-lg" type="button">{t("supportUs")}</button>
                </div>
            </div>
        </section>
        </>
    )
}
export default ThreeImagesInRow;