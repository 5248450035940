import { useTranslation } from "react-i18next";
import TitleHeading from "../components/TitleHeading";
import ImageWithDescription from "../components/ImageWithDescription";
import { replaceStringWithShandeChildAmount } from "../helpers/dataHelper";
import CareerJobPosition from "../components/CareerJobPosition";

function Career() {
    const {t} = useTranslation();
    document.title = 'Career | Shan De'; 
    return (
        <div className="container">
            <TitleHeading 
            title = {t("career")}
            className = "pt-5"
            />
            <ImageWithDescription
            img = "assets/img/career/recruit.png"
            description = {replaceStringWithShandeChildAmount(t("careerDescription"))}
            />

            <TitleHeading 
            title = {t("positionList")}
            subtitle = {t("fullTimeStaff")}
            className = "pt-5"
            />

            <CareerJobPosition
            type = "Full Time"
            />

            <TitleHeading 
            title = {t("positionList")}
            subtitle = {t("volunteer")}
            className = "pt-5"
            />

            <CareerJobPosition
            type = "Volunteer"
            />
        </div>
    )
}
export default Career;