import { useTranslation } from "react-i18next";
import ImageWithDescription from "../components/ImageWithDescription";
import TitleHeading from "../components/TitleHeading";
import { getRandomStaffData, replaceStringWithShandeAge, replaceStringWithShandeChildAmount } from "../helpers/dataHelper";
import ShowComponentOnClick from "../components/ShowComponentOnClick";
import { useSpring, animated, useScroll, useInView, Transition } from '@react-spring/web'
import ButtonFullRow from "../components/ButtonFullRow";
import { getLanguage } from "../helpers/storageHelper";
function DonationDetails() {
    document.title = 'Donation Details | Shan De'; 
    const { t } = useTranslation();
    const address = process.env.REACT_APP_SHANDE_ADDRESS;
    const [refShow, inViewShow] = useInView()
        const animatedDisplay1 = useSpring({
            opacity: inViewShow ? 1 : 0,
            config: { duration: 1000 }
        })
    const language = getLanguage();
    const redirectToRandomWhatsApp = (clickedButton) => {
        const randomContact = getRandomStaffData("Contact", "Marketing");
        const getMessage = (button = 1) => {
            if(button == 1) {
                if(language == 1) {
                    return `你好，我想捐赠善款`;
                }
                else {
                    return "Hello, I would like to donate";
                }
            }
            else {
                if(language == 1) {
                    return `你好，我想捐赠物资`;
                }
                else {
                    return "Hello, I would like to donate supplies";
                }
            }
        }
        const message = getMessage(clickedButton);
        const whatsappURL = `https://api.whatsapp.com/send?phone=6${randomContact}&text=${message}`;
        return whatsappURL;
        // window.location.href = whatsappURL;
    }
    const redirectToTouchNGo = () => {
        const touchNGoURL = process.env.REACT_APP_TOUCH_N_GO_URL;
        return touchNGoURL;
    }
    const button1 = {
        title: t("donateUs"),
        path: redirectToRandomWhatsApp(1)
    }
    const button2 = {
        title: t("donateSupplies"),
        path: redirectToRandomWhatsApp(2)
    }
    return (
        <>
        <div className="container pb-5">
            <TitleHeading
            title = {t("donationDetails")}
            className = "pt-4"
            />
            <ImageWithDescription 
            img = "assets/img/donation_details/donation_title_img.png"
            title = ""
            description = {replaceStringWithShandeChildAmount(replaceStringWithShandeAge(t("donationDetailsDescription")))}
            />

            <TitleHeading
            title = {t("donationWays")}
            className = "pt-4"
            />
            <ShowComponentOnClick />
            
            <animated.section ref={refShow} style={animatedDisplay1}>
                <TitleHeading
                title = {t("walkinDonation")}
                className = "pt-4"
                />
                <ImageWithDescription 
                img = "assets/img/donation_details/shande_home.png"
                title = ""
                description = {replaceStringWithShandeAge(t("walkinDonationDescription")) + "<br />" + t("address") + ": " + address}
                />
            </animated.section>
        </div>
        <ButtonFullRow
        button1 = {button1}
        button2 = {button2}
        />
        </>
    )
}
export default DonationDetails;