import React from "react";
import NineSeeMore from "../components/NineSeeMore";
import TitleHeading from "../components/TitleHeading";
import { getEventsPagination } from "../helpers/Api/apiHelper";
import MuiSkeleton from "../components/MuiSkeleton";
import { useTranslation } from "react-i18next";

function Events() {
    document.title = 'Events | Shan De'; 
    const [eventsData, setEventsData] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [totalPage, setTotalPage] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const {t} = useTranslation();

    const getEvents = async (pageNo) => {
        const response = await getEventsPagination(pageNo)
        if(response) {
            if(response.success) {
                setTotalPage(response.success.totalPage)
                if(eventsData != null) {
                    setEventsData(eventsData.concat(response.success.data));
                    setLoading(false);
                }
                else {
                    setEventsData(response.success.data);
                    setLoading(false);
                }
            }
            else {
                console.log(response.error);
                setEventsData(null);
                setLoading(false);
            }
        }
        else {
            setEventsData(null);
            setLoading(false);
        }
    }
    React.useEffect(() => {
        getEvents(page);
    },[page])

    const addExtraPage = () => {
        if(totalPage) {
            if((page + 1) < totalPage) {
                setPage(page + 1);
            }
        }
    }

    if(loading) return (
        <div className="container">
            <TitleHeading 
            title = {t("events")}
            className = "text-center pt-5"
            />
            <MuiSkeleton skeletonAmount = {9} />
        </div>
    )
    if(eventsData)
    return (
        <div className="container">
            <TitleHeading 
            title = {t("events")}
            className = "text-center pt-5"
            />
            <NineSeeMore 
            data = {eventsData}
            page = "events"
            />
            {
                totalPage && page + 1 < totalPage
                ?
                <div className="text-center">
                    <button type = "button" className="btn_see_more mb-3" onClick={addExtraPage}>
                        {t("loadMore")}
                    </button>
                </div>
                : ""
            }
        </div>
    )
}
export default Events;