function TitleHeading(props) {
    return (
        <>
            {
                props?.btnBackHref ?
                <div className="container back pt-4">
                    <a href = {props.btnBackHref} >
                        <i className="ion-chevron-left me-2" />
                        Back
                    </a>
                </div>
                : ""
            }
        <div className="font-title">
            <h2 className={props?.className}>{props?.title}<span>{props?.subtitle ? " - " + props.subtitle : ""}</span></h2>
        </div>
        </>
    )
}
export default TitleHeading;