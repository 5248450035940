import React from "react";
import TitleHeading from "../components/TitleHeading";
import { getRandomStaffData, getShandeAddressWithHref, getShandeContactWithHref1, getShandeContactWithHref2, getShandeEmailWithHref, getShandeFacebookWithHref, getStaticStaffNameAndNumber } from "../helpers/dataHelper";
import { useTranslation } from "react-i18next";

function ContactUs() {
    document.title = 'Contact Us | Shan De'; 
    const nameRef = React.useRef(null);
    const messageRef = React.useRef(null);
    const {t} = useTranslation();
    
    const redirectToWhatsApp = () => {
        let whatsappMessage = "";
        if(nameRef.current.value) {
            whatsappMessage += `${t("whatsappHi")} ${nameRef.current.value}. `
        }
        if(messageRef.current.value) {
            whatsappMessage += messageRef.current.value
        }
        const randomContact = getRandomStaffData("Contact", "Marketing");
        const whatsappURL = `https://api.whatsapp.com/send?phone=6${randomContact}&text=${whatsappMessage}`;
        window.location.href = whatsappURL;
    }
    return (
        <section class="portfolio-block contact pt-5">
            <div class="container">
                <TitleHeading
                title = {t("contactUs")}
                className = "text-center pb-4"
                />
                <form className="mw-100 contact-info">
					<div class="row">
						<div class="col-md-6">
							<h3 class="section-title">{t("ourAddress")}</h3>
							{/* <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p> */}
							<ul>
								<li><i class="ion-ios-location"></i>{getShandeAddressWithHref()}</li>
								<li><i class="ion-ios-telephone"></i>{getShandeContactWithHref1()} / {getShandeContactWithHref2()}</li>
								<li><i class="ion-android-mail"></i>{getShandeEmailWithHref()}</li>
								<li><i class="ion-social-facebook"></i>{getShandeFacebookWithHref()}</li>
							</ul>
						</div>
						<div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="name">{t("yourName")}</label>
                                <input class="form-control item" type="text" id="name" ref={nameRef}/>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="message">{t("message")}</label>
                                <textarea class="form-control item" id="message" ref={messageRef}></textarea>
                            </div>
                            <div class="mb-3">
                                <button class="btn btn-primary btn-lg d-block w-100" type="button" onClick={redirectToWhatsApp}>
                                    {t("contactViaWhatsapp")} 
                                    <img src="assets/img/WhatsApp.svg.webp" alt="WhatsApp Icon"
                                        style={{
                                        width: '30px',
                                        height: '30px',
                                        }}
                                    />
                                </button>
                            </div>
						</div>
					</div>
				</form>
            </div>
        </section>
    )
}
export default ContactUs;