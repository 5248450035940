import React from "react";

const SHANDE_API = process.env.REACT_APP_SHANDE_DOMAIN

// base_url => String
// api => String
// requestOptions => JSON
export function apiCall(method='GET', SERVER_URL, api_url, requestOptions = null) {
    var APIresult = null;
    const request = {
            // mode: 'no-cors',
            method: method,
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }
    if(requestOptions != null) {
        Object.assign(request, requestOptions)
    }

    if(method === 'GET') {
        APIresult = callApiGetMethod(SERVER_URL, api_url, request)
    }
    else {
        APIresult = callApiPostMethod(SERVER_URL, api_url, request)
    }
    return APIresult;
}

function callApiGetMethod(SERVER_URL, api_url, requestOptions) {
  const getData = async () => {
    const res = {};
      try {
          const response = await fetch(
              `${SERVER_URL}${api_url}`, requestOptions
          );
          if(!response.ok) {
              throw new Error(
                  `Error ${response.status}`
              );
          }
          let api_result = await response.json();
          res.success = api_result;
          return res;
      }
      catch(err) {
            res.error = err.message;
          // console.log(err.message)
          return res;
      }
  }
  return getData()
}

// post method usable for PUT and DELETE method
function callApiPostMethod(SERVER_URL, api_url, requestOptions) {
    console.log(SERVER_URL);
    console.log(api_url);
    console.log(requestOptions);
    const getPOSTData = async () => {
        const res = {};
        try {
            const response = await fetch(
                `${SERVER_URL}${api_url}`, requestOptions
            );
            // console.log(response);
            let result_text = await response.text();
            console.log(result_text);
            if(!response.ok) {
                if(response.status == '403' && result_text == "") {
                    result_text = JSON.stringify({
                        message: "您无权做出此指令，请联系系统负责人进行相关指令"
                    });
                }
                const error_result = {
                    backend_message: JSON.parse(result_text),
                    status: response.status,
                    ok: response.ok
                }
                throw new Error(
                    JSON.stringify(error_result)
                );
            }
            console.log(result_text)
            if(result_text.trim() != "") {
                let api_result = JSON.parse(result_text);
                res.success = api_result;
            }
            return res;
        }
        catch(err) {
            console.log(err)
            res.error = JSON.parse(err.message);
            return res;
        }
    }
    return getPOSTData()
}

export async function getNewsPagination(pageNo = 0) {
    return await apiCall('GET', SHANDE_API, `/news/pagination?page=${pageNo}`);
}

export async function getEventsPagination(pageNo = 0) {
    return await apiCall('GET', SHANDE_API, `/activityContent/pagination?page=${pageNo}`);
}

export async function getNewsById(id) {
    return await apiCall('GET', SHANDE_API, `/news/${id}`);
}

export async function getEventsById(id) {
    return await apiCall('GET', SHANDE_API, `/activityContent/${id}`);
}

export async function getFacebookUrl() {
    return await apiCall('GET', SHANDE_API, `/facebookUrl`);
}