import { useLocation } from "react-router";
import TitleHeading from "../components/TitleHeading";
import { getLanguage } from "../helpers/storageHelper";
import { useTranslation } from "react-i18next";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { getNewsById } from "../helpers/Api/apiHelper";
import LoadingScreen from "../components/LoadingScreen";
import MultilineContent from "../components/MultilineContent";

function NewsDetails() {
    const {state} = useLocation();
    const language = getLanguage();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')
    const {t} = useTranslation();
    const [newsData, setNewsData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const data = state ?? newsData;
    React.useEffect(() => {
        if(!state && id) {
            const getNews = async (id) => {
                const response = await getNewsById(id)
                if(response) {
                    if(response.success) {
                        setNewsData(response.success);
                        setLoading(false);
                    }
                    else {
                        console.log(response.error);
                        setNewsData(null);
                        setLoading(false);
                    }
                }
                else {
                    setNewsData(null);
                    setLoading(false);
                }
            }
            getNews(id);
        }
        else {
            setLoading(false)
        }
    },[])

    if(loading) return (<LoadingScreen />)

    if(data) {
        console.log(data)
        return (
            <section class="portfolio-block project p-0">
                <TitleHeading
                btnBackHref = "/news"
                title = {language == 1 ? data?.title : data?.enTitle}
                className = "text-center pt-4"
                />
                <div class="container">
                    <div class="img-container text-center mb-4">
                    <img src={data?.mainImageUrl} alt={data?.enTitle} class="images img-fluid mh-600" />
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 offset-md-1 info">
                            <h3>{t("description")}</h3>
                            <p id="content">{MultilineContent(language == 1 ? data?.content : data?.enContent)}</p>
                        </div>
                        <div class="col-12 col-md-3 offset-md-1 meta">
                            <div class="tags">
                                {/* <span class="meta-heading">Tags</span>
                                <a href="#">Resource</a>
                                <a href="#">Project</a> */}
                                <span class="meta-heading">{t("date")}</span>
                                <span>{data?.date}</span>
                            </div>
                        </div>
                    </div>
                    <div class="more-projects">
                        <h3 class="text-center">{t("moreImage")}</h3>
                        <div class="row gallery">
                            {
                                data?.photoUrls && data?.photoUrls.map((item) => (
                                    <div class="col-md-4 col-lg-3 item">
                                        <div class="item m-0">
                                            <img class="img-fluid scale-on-hover" src={item?.url} alt="Other Images" />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default NewsDetails;