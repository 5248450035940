import { useTranslation } from "react-i18next";
import ImageWithDescription from "../components/ImageWithDescription";
import TitleHeading from "../components/TitleHeading";
import { replaceStringWithShandeAge, replaceStringWithShandeChildAmount, replaceStringWithShandeTotalHelpedChildAmount } from "../helpers/dataHelper";
import TwoTitleAndDescription from "../components/TwoTitleAndDescription";
import OurLicense from "../components/OurLicense";
import ShandeWordsAndImages from "../components/ShandeWordsAndImages";

function AboutUs() {
    document.title = 'About Us | Shan De'; 
    const {t} = useTranslation();
    return (
        <div className="container">
        <TitleHeading
        title = {t("aboutUs")}
        className = "pt-5"
        />
        <ImageWithDescription 
        img = "assets/img/about_us/house-cartoon.jpg"
        description = {replaceStringWithShandeTotalHelpedChildAmount(replaceStringWithShandeChildAmount(replaceStringWithShandeAge(t("aboutUsDescription"))))}
        />
        <TwoTitleAndDescription />

        <OurLicense />
        
        <TitleHeading
        title = "S H A N D E"
        className = "pt-5 pb-4 mt-5"
        />
        <ShandeWordsAndImages />
        </div>
    )
}
export default AboutUs;