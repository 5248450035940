import React from "react";

function TngIcon() {
    const tngUrl = process.env.REACT_APP_TOUCH_N_GO_URL;
    return (
        <a href={tngUrl} target="_blank" rel="noopener noreferrer">
        <img
            src="assets/img/tng_icon.png"
            alt="TouchNGo Icon"
            style={{
            position: 'fixed',
            bottom: '80px',
            right: '20px',
            width: '50px',
            height: '50px',
            zIndex: '9999',
            }}
        />
        </a>
    )
}
export default TngIcon;