import { getLanguage } from "../storageHelper";

export function selectCharitySalesData() {
    let data = null;
    const language = getLanguage() ?? 1;
    if(language == 1) {
        data = [
            {
            id: 1,
            image: 'assets/img/charity_sale/sdlogo_sale.jpg',
            name: '善德Logo',
            description: '此Logo是由我们较大的几位中学生耗费了数个星期时间将细小的颗粒一颗一颗粘在模板上制成的，过程极其艰辛。<br />此作品富含了非常有耐心和毅力的数位孩子们大量的汗水和精力。希望能以自己小小的力量为自己的家（善德）做出一份贡献！',
            price: 'RM 1888',
            },
            {
            id: 2,
            image: 'assets/img/charity_sale/cat_sale.jpg',
            name: '孩子制作的招财猫',
            description: '此招财猫是由我们较大的几位中学生耗费了数个星期时间将细小的颗粒一颗一颗粘在模板上制成的，过程极其艰辛。<br />此作品富含了非常有耐心和毅力的数位孩子们大量的汗水和精力。希望能以自己小小的力量为自己的家（善德）做出一份贡献！',
            price: 'RM 1688',
            },
            {
            id: 3,
            image: 'assets/img/charity_sale/child_art_1.jpg',
            name: '孩子手工作品(一)',
            description: '此作品由众多较小的孩子们在老师的教导下合力完成，其中的心形热气球是由孩子们亲手制作并把他们所想表达的一一写在“心形气球”上，让气球把他们想说的话带到天上去！<br />作品完成后再加上灯光效果，把整幅画都提高了一个层次！',
            price: 'RM 1288',
            },
            {
            id: 4,
            image: 'assets/img/charity_sale/child_art_2.jpg',
            name: '孩子手工作品(二)',
            description: '此作品由众多较小的孩子们在老师的教导下合力完成，其中的心形热气球是由孩子们亲手制作并把他们所想表达的一一写在“心形气球”上，让气球把他们想说的话带到天上去！<br />作品完成后再加上灯光效果，把整幅画都提高了一个层次！',
            price: 'RM 1288',
            },
            {
            id: 5,
            image: 'assets/img/charity_sale/consecrated_pic.jpg',
            name: '缅甸高僧开光之作',
            description: '先前善德负责人有幸获得缅甸高僧开光过的作品，据说开光后的这个作品能够保佑持有者生意兴隆哦！',
            price: 'RM 1388',
            },
            
        ]
    }
    else {
        data = [
            {
              "id": 1,
              "image": "assets/img/charity_sale/sdlogo_sale.jpg",
              "name": "Shan De Logo",
              "description": "This logo was meticulously crafted by our older high school students, who spent several weeks attaching tiny particles one by one onto a template. The process was extremely challenging. This work is filled with the dedication, sweat, and energy of these young children, showcasing their patience and perseverance. We hope to contribute in our own small way to our home (Shan De)!",
              "price": "RM 1888"
            },
            {
              "id": 2,
              "image": "assets/img/charity_sale/cat_sale.jpg",
              "name": "Handcrafted Prosperity Cat by Children",
              "description": "This prosperity cat was meticulously crafted by our older high school students, who spent several weeks attaching tiny particles one by one onto a template. The process was extremely challenging. This work is filled with the dedication, sweat, and energy of these young children, showcasing their patience and perseverance. We hope to contribute in our own small way to our home (Shan De)!",
              "price": "RM 1688"
            },
            {
              "id": 3,
              "image": "assets/img/charity_sale/child_art_1.jpg",
              "name": "Children's Handcrafted Artwork (One)",
              "description": "This artwork was collaboratively created by numerous younger children under the guidance of their teachers. The heart-shaped hot air balloon was handmade by the children, and they wrote their thoughts and messages on each 'heart balloon,' allowing their messages to be carried up to the sky by the balloons! After completion, the artwork was enhanced with lighting effects, elevating the entire piece.",
              "price": "RM 1288"
            },
            {
              "id": 4,
              "image": "assets/img/charity_sale/child_art_2.jpg",
              "name": "Children's Handcrafted Artwork (Two)",
              "description": "This artwork was collaboratively created by numerous younger children under the guidance of their teachers. The heart-shaped hot air balloon was handmade by the children, and they wrote their thoughts and messages on each 'heart balloon,' allowing their messages to be carried up to the sky by the balloons! After completion, the artwork was enhanced with lighting effects, elevating the entire piece.",
              "price": "RM 1288"
            },
            {
              "id": 5,
              "image": "assets/img/charity_sale/consecrated_pic.jpg",
              "name": "Blessed Artwork by Burmese Monk",
              "description": "Previously, the head of Shan De was fortunate to obtain this artwork blessed by a Burmese monk. It is said that this blessed artwork can bring prosperity to its owner's business!",
              "price": "RM 1388"
            }
        ]
    }
    return data;
}