import { useTranslation } from "react-i18next";
import ImageWithDescription from "../components/ImageWithDescription";
import TitleHeading from "../components/TitleHeading";
import { replaceStringWithShandeAge } from "../helpers/dataHelper";
import ImageAndDetails2 from "../components/ImageAndDetails2";
import { getOrganizationScope, getOurConsultant } from "../helpers/StaticData/ourOrganizationData";
import { useSpring, animated, useScroll, useInView, Transition } from '@react-spring/web';
import { useMediaQuery } from 'react-responsive';
function OurOrganization() {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    console.log(isMobile)
    const [refSlideRightToLeft, inViewSlideRightToLeft] = useInView()
        const animatedDisplay1 = useSpring({
            opacity: inViewSlideRightToLeft ? 1 : 0,
            transform: inViewSlideRightToLeft ? "translateX(0px)" : "translateX(100px)",
            config: { duration: 1000 }
        })
    const [refSlideLeftToRight, inViewSlideLeftToRight] = useInView()
        const animatedDisplay2 = useSpring({
            opacity: inViewSlideLeftToRight ? 1 : 0,
            transform: inViewSlideLeftToRight ? "translateX(0px)" : "translateX(-100px)",
            config: { duration: 1000 }
        })
    const [refSlideUp, inViewSlideUp] = useInView()
        const animatedDisplay3 = useSpring({
            opacity: inViewSlideUp ? 1 : 0,
            transform: inViewSlideUp ? "translateY(0px)" : "translateY(100px)",
            config: { duration: 1000 }
        })
    document.title = 'Our Organization | Shan De'; 
    const {t} = useTranslation();
    const consultantData = getOurConsultant();
    const scopeData = getOrganizationScope();
    return (
        <div className="container">
            <TitleHeading
            title = {t("ourOrganization")}
            className = "pt-4"
            />
            
            <ImageWithDescription 
            img = "assets/img/our_organization/2019.jpg"
            description = {t("ourOrganizationDescription")}
            />

            <animated.section ref={refSlideLeftToRight} style={!isMobile ? animatedDisplay2 : {}}>
                <ImageWithDescription 
                img = "assets/img/our_organization/sammi.jpg"
                title = {t("ourOrganizationSammiTitle")}
                description = {replaceStringWithShandeAge(t("ourOrganizationSammiWords"))}
                imgPortrait = {true}
                reverse = {true}
                />
            </animated.section>

            <animated.section ref={refSlideRightToLeft} style={!isMobile ? animatedDisplay1 : {}}>
                <ImageWithDescription 
                img = "assets/img/our_organization/simon.jpg"
                title = {t("ourOrganizationSimonTitle")}
                description = {t("ourOrganizationSimonWords")}
                imgPortrait = {true}
                />
            </animated.section>

            <TitleHeading
            title = {t("ourConsultant")}
            className = "pt-5 text-center"
            />

            <animated.section ref={refSlideUp} style={animatedDisplay3}>
                <ImageAndDetails2
                data = {consultantData}
                />
            </animated.section>

            <TitleHeading
            title = {t("ourOrganizationScope")}
            className = "pt-5 text-center"
            />

            {
                scopeData && scopeData.map((item) => (
                    <ImageWithDescription
                    img = {item?.img}
                    title = {item?.title}
                    description = {item?.description}
                    imgCircle = {true}
                    />
                ))
            }
        </div>
    )
}
export default OurOrganization;