import { getShanDeChildrenAmount, getSimonNameAndPhoneNumber } from "../dataHelper";
import { getLanguage } from "../storageHelper";

const shandeAddress = process.env.REACT_APP_SHANDE_ADDRESS;
export function getFaqObject() {
    let faqObject = null;
    const language = getLanguage() ?? 1;
    if(language == 1) {
      faqObject = [
        {
          question: "请问你们有开放领养吗？",
          answer: "不好意思，我们 <strong>没有</strong> 开放领养的，我们只有助养计划，如果您有兴趣，可以 <a class='faq_a' href='/sponsorshipPlan'>点我</a> 了解更多。"
        },
        {
          question: "请问你们中心位于哪里？",
          answer: "我们位于 <strong>马来西亚新山大马花园</strong> 。这是我们的地址： <strong>" + shandeAddress +"</strong>"
        },
        {
          question: "请问你们中心目前有多少位孩子？",
          answer: "我们中心目前有 <strong>" + getShanDeChildrenAmount() +"</strong> 位小朋友哦！"
        },
        {
          question: "请问可以前去中心探访孩子吗？",
          answer: "可以上门探访的，我们每天都是10am-6pm之间开放。"
        },
        {
          question: "请问捐款会有收据的吗？",
          answer: "会的，所有的上门捐款或转账捐款我们都会开出收据的，现金捐款会马上开出收据，转账捐款则会在两个星期内查询后尽快开出给捐款者。"
        },
        {
          question: "请问你们在KL或其他地区有分行吗？",
          answer: "<strong>没有</strong>，我们善德儿童福利之家全马只有一间而已的，位于<strong>马来西亚新山大马花园</strong> 。这是我们的地址： <strong>"+shandeAddress+"</strong>"
        },
        {
          question: "请问你们那边的孩子都是孤儿吗？",
          answer: "我们收容孩子的范围都是一些没有能力照顾孩子的家庭，如：<li>单亲家庭</li><li>父母双亡</li><li>被遗弃的孩子</li><li>遭受家暴的家庭</li>目前中心的孩子都是来自单亲家庭居多，想了解更多我们的收容范围，可以<a class='faq_a' href='ourOrganization'>点击这里</a>。"
        },
        {
          question: "我想把孩子送到你们中心，需要什么条件？",
          answer: "若是您的家庭有困难，需要我们中心的帮忙，可以联系负责人 <strong>"+getSimonNameAndPhoneNumber() +"</strong> 了解情况。"
        },
      ]
    }
    else {
      faqObject = [
        {
          question: "Do you offer adoption services?",
          answer: "I'm sorry, we do <strong>not</strong> offer adoption services. We have a sponsorship program instead. If you are interested, you can learn more by clicking <a class='faq_a' href='/sponsorshipPlan'>here</a>."
        },
        {
          question: "Where is your center located?",
          answer: "We are located in <strong>Taman Sri Tebrau, Johor Bahru, Malaysia</strong>. Here's our address: <strong>" + shandeAddress +"</strong>."
        },
        {
          question: "How many children are currently at your center?",
          answer: "We currently have <strong>" + getShanDeChildrenAmount() +"</strong> children at our center!"
        },
        {
          question: "Can I visit the center and meet the children?",
          answer: "Yes, you can visit the center. We are open for visits every day between 10 am and 6 pm."
        },
        {
          question: "Will I receive a receipt for my donation?",
          answer: "Yes, we provide receipts for all in-person or bank transfer donations. Cash donations will receive an immediate receipt, while bank transfer donations will be issued to the donor within two weeks after verification."
        },
        {
          question: "Do you have branches in Kuala Lumpur or other areas?",
          answer: "No, we only have one branch of Shan De in Malaysia, located in <strong>Taman Sri Tebrau, Johor Bahru</strong>. Here's our address: <strong>"+shandeAddress+"</strong>."
        },
        {
          question: "Are all the children in your care orphans?",
          answer: "The children we care for come from families who are unable to take care of them, such as: <li>Single-parent families</li><li>Orphans</li><li>Abandoned children</li><li>Victims of domestic violence</li>Currently, most of the children at our center come from single-parent families. To learn more about the criteria for accepting children into our care, you can click <a class='faq_a' href='ourOrganization'>here</a>."
        },
        {
          question: "What are the conditions for sending a child to your center?",
          answer: "If your family is facing difficulties and needs assistance from our center, you can contact the person in charge, <strong>"+getSimonNameAndPhoneNumber()+"</strong>, to discuss the situation."
        },
      ];
    }
    return faqObject;
}