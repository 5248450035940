import { useTranslation } from "react-i18next";

function CareerDetails(props) {
    const data = props?.data;
    const {t} = useTranslation();
    return (
        <div>
            {t("education")}: {data?.education}
            <br /><br />
            {t("workExperience")}: {data?.work_experience}
            <br /><br />
            {t("jobScope")}: 
            <br />
            {
                data?.job_scope && data.job_scope.map((item) => (
                    <li>{item}</li>
                ))
            }
            <br />
            {t("jobRequirement")}: 
            {
                data?.job_requirement && data.job_requirement.map((item) => (
                    <li>{item}</li>
                ))
            }
            <br />
            {
                data?.we_provide ?
                <>
                {t("weProvide")}: 
                {
                    data?.we_provide && data.we_provide.map((item) => (
                        <li>{item}</li>
                    ))
                }
                <br />
                </>
                : ""
            }
            {t("ifInterestedPleaseContact")}: 
            {
                data?.contact && data.contact.map((item) => (
                    <li>{item.name} {item.contact}</li>
                ))
            }
        </div>
    )
}  
export default CareerDetails;