import React, { useState, useEffect } from 'react';
import './Slideshow.css'; // Create a CSS file to style the slideshow
import { useMediaQuery } from 'react-responsive';
const Slideshow = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const photo_url = process.env.REACT_APP_SHANDE_PHOTO_URL;
  const images = !isMobile ? [
    photo_url + "homepage/shande.jpg",
    photo_url + "homepage/waumerak.jpg",
    photo_url + "homepage/nyonya.jpg",
  ] 
  : [
    photo_url + "homepage/shande_mobile.jpg",
    photo_url + "homepage/waumerak.jpg",
    photo_url + "homepage/nyonya_mobile.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  // Automatic slideshow
  useEffect(() => {
    const interval = setInterval(nextSlide, 7000); // Change slide every 7 seconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slideshow-container">
      <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="slide-image" />
      {/* <p>hello</p> */}
      <div className="prev" onClick={prevSlide}>
        &#10094;
      </div>
      <div className="next" onClick={nextSlide}>
        &#10095;
      </div>
    </div>
  );
};

export default Slideshow;
