export function getLanguage() {
    if(localStorage.getItem('language') != null) {
        return localStorage.getItem('language');
    }
    else {
        setLanguage(1);
        window.location.reload();
        return 1;
    }
}

export function setLanguage(lang) {
    if(lang) {
        localStorage.setItem('language', lang);
    }
}

export function getLanguageName() {
    if(getLanguage() == 1) {
        return 'English';
    }
    else if(getLanguage() == 2) {
        return '中文';
    }
    else {
        return 'English';
    }
}