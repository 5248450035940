import React from 'react';
import './ButtonFullRow.css'; // Make sure to have a corresponding CSS file for styling

const ButtonFullRow = ({button1, button2}) => {
  return (
      <div className="background-row">
        <a href={button1?.path} target="_blank" rel="noreferrer">
            <button className="donate-button">{button1?.title}</button>
        </a>
        <a href={button2?.path} target="_blank" rel="noreferrer">
            <button className="join-button">{button2?.title}</button>
        </a>

      </div>
  );
};

export default ButtonFullRow;
