import React, { useRef } from "react";
import { useSpring, animated, useScroll, useInView } from '@react-spring/web'
import { getCurrentShandeAge, getShanDeChildrenAmount, getShanDeTotalHelpedChildrenAmount } from "../helpers/dataHelper";
import { useTranslation } from "react-i18next";
function OurData() {
    const [ref, inView] = useInView()
    const {t} = useTranslation();
    const myDuration = 3000;
    
    const animatedValue1 = useSpring({
        from: { value: 0 },
        to: inView ? { value: getCurrentShandeAge() } : 0,
        config: { duration: myDuration },
    })
    const animatedValue2 = useSpring({
        from: { value: 0 },
        to: inView ? { value: getShanDeTotalHelpedChildrenAmount() } : 0,
        config: { duration: myDuration },
    })
    const animatedValue3 = useSpring({
        from: { value: 0 },
        to: inView ? { value: getShanDeChildrenAmount() } : 0,
        config: { duration: myDuration },
    })
    const animatedValue4 = useSpring({
        from: { value: 0 },
        to: inView ? { value: 100 } : 0,
        config: { duration: myDuration },
    })

    // console.log(ref)
    // console.log(inView)


    return (
        <section ref={ref} className="py-3 py-xl-5 mb-5 text-center">
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-3">
                        <animated.h3>{inView ? animatedValue1.value.to(x => x.toFixed(0)) : 0}</animated.h3>
                        <h6>Years Helping Business</h6>
                    </div> */}
                    <div className="col-md-4">
                        <animated.h3>{inView ? animatedValue1.value.to(x => x.toFixed(0)) : 0}</animated.h3>
                        <h6>{t("yearsOfServices")}</h6>                        
                    </div>
                    <div className="col-md-4">
                        <animated.h3>{inView ? animatedValue2.value.to(x => x.toFixed(0)) : 0}</animated.h3>
                        <h6>{t("totalHelpedChildren")}</h6>
                    </div>
                    <div className="col-md-4">
                        <animated.h3>{inView ? animatedValue3.value.to(x => x.toFixed(0)) : 0}</animated.h3>
                        <h6>{t("currentChildren")}</h6>
                    </div>
                    {/* <div className="col-md-3">
                        <animated.h3>{inView ? animatedValue4.value.to(x => x.toFixed(0)+"+") : 0}</animated.h3>
                        <h6>5 Star Rating</h6>
                    </div> */}
                </div>
            </div>
        </section>
    )
}
export default OurData;