import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function MuiSkeleton(props) {
    const skeletonAmount = props?.skeletonAmount ?? 1;
    return (
        <div className = "container">
            <div className = "row">
            {
                Array.from({ length: skeletonAmount }, (_, i) => 
                    <div className='col-md-6 col-lg-4'>
                        <Stack spacing={1}>
                        {/* For variant="text", adjust the height via font-size */}
                        {/* For other variants, adjust the size with `width` and `height` */}
                        {/* <Skeleton variant="circular" width={40} height={40} /> */}
                        <Skeleton variant="rectangular" width={"100%"} height={230} />
                        <Skeleton variant="text" sx={{ fontSize: '0.1rem' }} />
                        <Skeleton variant="rectangular" width={"100%"} height={230} />
                        {/* <Skeleton variant="rounded" width={210} height={60} /> */}
                        </Stack>
                    </div>
                )
            }
            </div>
        </div>
    );
}