import { getLanguage } from "../storageHelper";

const photo_url = process.env.REACT_APP_SHANDE_PHOTO_URL;
export function getOrganizationScope() {
    const language = getLanguage() ?? 1;
    let ourOrganizationScope = null;
    if (language == 1) {
      ourOrganizationScope = [
        {
          img: photo_url + "our_organization/orphan.png",
          title: "孤儿",
          description: "失去双亲或无依靠者。"
        },
        {
          img: photo_url + "our_organization/poor.png",
          title: "贫困",
          description: "缺乏经济能力或照顾能力的家庭，或因意外、疾病或功能失调而导致无法照顾儿童。"
        },
        {
          img: photo_url + "our_organization/abandon.png",
          title: "遗弃",
          description: "因某种原因被亲人遗弃者。"
        },
        {
          img: photo_url + "our_organization/abuse.png",
          title: "虐待",
          description: "被亲人极度虐待者。(需通过警方或法院程序)"
        },
        {
          img: photo_url + "our_organization/single.png",
          title: "单亲",
          description: "由单亲家庭抚养的孩子。(离婚，贫穷，意外等)"
        },
      ]
    }
    else {
      ourOrganizationScope = [
        {
          img: photo_url + "our_organization/orphan.png",
          title: "Orphans",
          description: "Children who have lost both parents or have no one to rely on."
        },
        {
          img: photo_url + "our_organization/poor.png",
          title: "Poverty",
          description: "Families lacking economic capability or caregiving ability, or unable to care for children due to accidents, illness, or disabilities."
        },
        {
          img: photo_url + "our_organization/abandon.png",
          title: "Abandoned",
          description: "Individuals who have been abandoned by their relatives for various reasons."
        },
        {
          img: photo_url + "our_organization/abuse.png",
          title: "Abused",
          description: "Individuals who have suffered extreme abuse from their relatives. (Requires involvement of law enforcement or legal procedures.)"
        },
        {
          img: photo_url + "our_organization/single.png",
          title: "Single-parent",
          description: "Children raised in single-parent households due to divorce, poverty, accidents, etc."
        },
      ]    
    }
    return ourOrganizationScope;
}

export function getOurConsultant() {
    const language =  getLanguage() ?? 1;
    let ourConsultant = null;
    if (language == 1) {
        ourConsultant = [
            {
            img: photo_url + "our_organization/consultant/yb_tan_sri_haji_shahrir.jpg",
            title: "监护人",
            name: "YB Tan Sri Haji Shahrir"
            },
            {
            img: photo_url + "our_organization/consultant/datuk_yahya_bin_jaafar.jpg",
            title: "顾问",
            name: "Datuk Yahya Bin Jaafar"
            },
            {
            img: photo_url + "our_organization/consultant/zhang_guo_zhi.jpg",
            title: "顾问",
            name: "拿督张国智(上议员)"
            },
            {
            img: photo_url + "our_organization/consultant/zhang_xiu_fu.jpg",
            title: "顾问",
            name: "拿督张秀福"
            },
            {
            img: photo_url + "our_organization/consultant/tuan_haji_mohd_sunawan.jpg",
            title: "顾问",
            name: "Tuan Haji Mohd Sunawan Bin Haji Md Som"
            },
            {
            img: photo_url + "our_organization/consultant/mr_see.jpg",
            title: "顾问",
            name: "拿督施亚历"
            },
            {
            img: photo_url + "our_organization/consultant/mr_koo.jpg",
            title: "顾问",
            name: "邱孝利(民政党副主席)"
            },
            {
            img: photo_url + "our_organization/consultant/lim_gang_rong.jpg",
            title: "顾问",
            name: "林刚荣 PIS"
            },
            {
            img: photo_url + "our_organization/consultant/soon_hai.jpg",
            title: "顾问",
            name: "倪顺海(市议员)"
            },
            {
            img: photo_url + "our_organization/consultant/huang_you_feng.jpg",
            title: "法律顾问",
            name: "黄友凤律师"
            },
            {
            img: photo_url + "our_organization/consultant/huang_ming_shan.jpg",
            title: "法律顾问",
            name: "黄明山律师"
            },
            {
            img: photo_url + "our_organization/consultant/dr_xiao.jpg",
            title: "医药顾问",
            name: "萧升医生"
            },
            {
            img: photo_url + "our_organization/consultant/she_gen_yao.jpg",
            title: "医药顾问",
            name: "余根耀医生"
            },
            {
            img: photo_url + "our_organization/consultant/du_ming_feng.jpg",
            title: "医药顾问",
            name: "杜明峰医生"
            },
        ]
    }
    else {
        ourConsultant = [
            {
              "img": photo_url + "our_organization/consultant/yb_tan_sri_haji_shahrir.jpg",
              "title": "Guardian",
              "name": "YB Tan Sri Haji Shahrir"
            },
            {
              "img": photo_url + "our_organization/consultant/datuk_yahya_bin_jaafar.jpg",
              "title": "Advisor",
              "name": "Datuk Yahya Bin Jaafar"
            },
            {
              "img": photo_url + "our_organization/consultant/zhang_guo_zhi.jpg",
              "title": "Advisor",
              "name": "Datuk Teo Kok Chee (Senator)"
            },
            {
              "img": photo_url + "our_organization/consultant/zhang_xiu_fu.jpg",
              "title": "Advisor",
              "name": "Datuk Jason Teoh Sew Hock"
            },
            {
              "img": photo_url + "our_organization/consultant/tuan_haji_mohd_sunawan.jpg",
              "title": "Advisor",
              "name": "Tuan Haji Mohd Sunawan Bin Haji Md Som"
            },
            {
              "img": photo_url + "our_organization/consultant/mr_see.jpg",
              "title": "Advisor",
              "name": "Datuk See Cha Tech"
            },
            {
              "img": photo_url + "our_organization/consultant/mr_koo.jpg",
              "title": "Advisor",
              "name": "Mr. Koo Shiaw Lee (Vice Chairman of DAP)"
            },
            {
              "img": photo_url + "our_organization/consultant/lim_gang_rong.jpg",
              "title": "Advisor",
              "name": "Lim Kong Yong (PIS)"
            },
            {
              "img": photo_url + "our_organization/consultant/soon_hai.jpg",
              "title": "Advisor",
              "name": "Lim Soon Hai (City Councillor)"
            },
            {
              "img": photo_url + "our_organization/consultant/huang_you_feng.jpg",
              "title": "Legal Advisor",
              "name": "Lawyer Wong You Fong"
            },
            {
              "img": photo_url + "our_organization/consultant/huang_ming_shan.jpg",
              "title": "Legal Advisor",
              "name": "Lawyer Michael Wong"
            },
            {
              "img": photo_url + "our_organization/consultant/dr_xiao.jpg",
              "title": "Medical Advisor",
              "name": "Dr. Xiao Sheng"
            },
            {
              "img": photo_url + "our_organization/consultant/she_gen_yao.jpg",
              "title": "Medical Advisor",
              "name": "Dr. William"
            },
            {
              "img": photo_url + "our_organization/consultant/du_ming_feng.jpg",
              "title": "Medical Advisor",
              "name": "Dr. Toh Ming Feng"
            }
        ]          
    }
    return ourConsultant;
}