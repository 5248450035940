import { Link } from "react-router-dom";
import { getLanguage } from "../helpers/storageHelper";

function NineSeeMore(props) {
    const data = props?.data;
    const page = props?.page;
    const language = getLanguage();
    const photoURL = process.env.REACT_APP_SHANDE_PHOTO_URL;
    return (
        <section class="pt-3">
            <div class="container">
                <div class="row">
                    {
                        data && data.map(item => (
                            <div class="col-md-6 col-lg-4 zoom-on-hover">
                                <div class="item transition text-center news-image-container">
                                    <img class="mw-100 mh-100 image" src={item?.mainImageUrl} alt={item?.enTitle} />
                                </div>
                                <div class="project-card-no-image">
                                    <h3>{language == 1 ? item?.title : item?.enTitle}</h3>
                                    <h4>{language == 1 ? item?.content : item?.enContent}</h4>
                                    <Link class="btn btn-outline-primary btn-sm" to={`/${page}Details?id=${item?.id}`} state={item}>See More</Link>
                                    <div class="tags"><p>{item?.date}</p></div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}
export default NineSeeMore;