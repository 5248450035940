import { getLanguage } from "../storageHelper";

export function getShandeExplanation() {
    const photo_url = process.env.REACT_APP_SHANDE_PHOTO_URL;
    const language =  getLanguage() ?? 1;
    let explanation = null;
    if (language == 1) {
      explanation = [
        {
          "title": "Shelter",
          "text": [
            "一个庇护",
            "一个栖身之处",
            "一个遮风挡雨的家园",
          ],
          "img": photo_url + "about_us/shelter.jpg",
        },
        {
          "title": "Hope",
          "text": [
            "一个希望",
            "一个梦想",
            "一个目标",
          ],
          "img": photo_url + "about_us/hope.jpg",
        },
        {
          "title": "Attitude",
          "text": [
            "一种向上向善的人生态度",
            "一种正面积极的处理方法",
          ],
          "img": photo_url + "about_us/attitude.jpg",
        },
        {
          "title": "Nature",
          "text": [
            "一个寻回及展现原有童真的心灵之处",
          ],
          "img": photo_url + "about_us/nature.jpg",
        },
        {
          "title": "Duty",
          "text": [
            "一个生活在世界上属于每个人每个角色里独一无二的使命与自身价值",
          ],
          "img": photo_url + "about_us/duty.jpg",
        },
        {
          "title": "Enthusiasm",
          "text": [
            "一个热忱，一份力量，一股勇气地迎向生命的未来",
          ],
          "img": photo_url + "about_us/enthusiasm.jpg",
        },
      ]
    }
    else {
      explanation = [
        {
          "title": "Shelter",
          "text": [
            "A refuge",
            "A place of shelter",
            "A home that provides protection and comfort",
          ],
          "img": photo_url + "about_us/shelter.jpg"
        },
        {
          "title": "Hope",
          "text": [
            "A source of hope",
            "A dream",
            "A goal",
          ],
          "img": photo_url + "about_us/hope.jpg"
        },
        {
          "title": "Attitude",
          "text": [
            "A positive and virtuous attitude towards life",
            "A proactive and optimistic approach to handling situations",
          ],
          "img": photo_url + "about_us/attitude.jpg"
        },
        {
          "title": "Nature",
          "text": [
            "A place to rediscover and express the innate innocence of the heart",
          ],
          "img": photo_url + "about_us/nature.jpg"
        },
        {
          "title": "Duty",
          "text": [
            "A unique mission and intrinsic value that belongs to each individual and role in the world",
          ],
          "img": photo_url + "about_us/duty.jpg"
        },
        {
          "title": "Enthusiasm",
          "text": [
            "A fervor, a force, and a courage to embrace the future of life",
          ],
          "img": photo_url + "about_us/enthusiasm.jpg"
        },
      ];
    }
    return explanation;
}