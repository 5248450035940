function CollapseData(props) {
    const data = props?.data;
    console.log(data);
    function getAnswer(index, ans) {
        const doc = document.getElementById(`faq-container-${index}`);
        doc.innerHTML = ans;
    }
    return (
        <div class="container">
            <div class="row">
                <div id="faqlist" class="accordion accordion-flush">
                    {
                        data && data.map((item, index) => (
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button onClick={()=>getAnswer(index, item?.answer)} class="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#content-accordion-${index}`}>
                                        {index+1 + ". " + item?.question}
                                    </button>
                                </h2>
                                <div id={`content-accordion-${index}`} class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                    <p id={`faq-container-${index}`} class="accordion-body"></p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
export default CollapseData;