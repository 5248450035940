import { useTranslation } from "react-i18next";
import TitleHeading from "../components/TitleHeading";
import ProductSlideShow from "../components/ProductSlideShow";

function CharitySales() {
    document.title = 'Charity Sales | Shan De'; 
    const {t} = useTranslation();
    return (
        <div className="container">
            <TitleHeading
            title = {t("charitySales")}
            className = "pt-5"
            />

            <ProductSlideShow />
        </div>
    )
}
export default CharitySales;