import { SplitBRString } from "../helpers/dataHelper";

function ImageWithDescription(props) {
    // pass in img, title, description, imgPortrait, reverse, imgCircle
    if (props?.imgFlexible == true) {
        // suitable for both landscape and portrait images
        return (
            <div class={`img-with-description mt-4 ${props?.className}`}>
                <div class="row">
                    <div class={`col-md-5 col-xl-5 ${props?.reverse == true ? " order-md-1" : ""}`}>
                        <div class="item">
                            <img src={props?.img} alt={props?.title} />
                        </div>
                    </div>
                    <div class="col-md-7 col-xl-7">
                        <p class="align-items-center">
                        <h5 class="text-center"><strong>{props?.title}</strong></h5>
                        <br />
                        <span>{SplitBRString(props?.description)}</span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    else if (props?.imgCircle == true) {
        // our organization page
        return (
            <div class={`img-with-description mt-4 ${props?.className}`}>
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <div class="text-center d-flex align-items-center justify-content-center img_container">
                            <img src={props?.img} alt={props?.title} class="mw-100" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-8 d-flex align-items-center">
                        <p class="align-items-center">
                        <h5>{props?.title}</h5>
                        <br />
                        <span>{SplitBRString(props?.description)}</span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    else if (props?.imgPortrait == null) {
        // normal use landscape
        return (
            <div class={`img-with-description mt-4 ${props?.className}`}>
                <div class="row">
                    <div class={`col-md-5 col-xl-5 ${props?.reverse == true ? " order-md-1" : ""}`}>
                        <div class="text-center d-flex align-items-center img_container">
                            <img src={props?.img} alt={props?.title} class="img_landscape" />
                        </div>
                    </div>
                    <div class="col-md-7 col-xl-7">
                        <p class="align-items-center">
                        <h5 class="text-center"><strong>{props?.title}</strong></h5>
                        <br />
                        <span>{SplitBRString(props?.description)}</span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    else {
        // normal use portrait
        return (
            <div class={`img-with-description mt-4 ${props?.className}`}>
                <div class="row">
                    <div class={`col-md-4 col-xl-4 ${props?.reverse == true ? " order-md-1" : ""}`}>
                        <div class="text-center d-flex align-items-center justify-content-center img_container">
                            <img src={props?.img} alt={props?.title} class="img_portrait" />
                        </div>
                    </div>
                    <div class="col-md-8 col-xl-8 d-flex align-items-center">
                        <p>
                        <h2 class="text-center p-0 mt-4">{props?.title}</h2>
                        <br />
                        <span>{SplitBRString(props?.description)}</span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default ImageWithDescription;