import { useTranslation } from "react-i18next";
import TitleHeading from "./TitleHeading";

function OurLicense() {
    const photo_url = process.env.REACT_APP_SHANDE_PHOTO_URL;
    const {t} = useTranslation();
    return (
        <>
        <div className="our-license pt-5">
        <TitleHeading
        title = {t("ourLicense")}
        className = "pb-4"
        />
            <div className="row">
                <div className="col-md-6 col-lg-3 our-license-img-container">
                    <img src={`${photo_url}/about_us/license/jkm_license.jpg`} alt="jkm license" />
                </div>
                <div className="col-md-6 col-lg-3 our-license-img-container">
                    <img src={`${photo_url}/about_us/license/jppm_license.jpg`} alt="jppm license" />
                </div>
                <div className="col-md-6 col-lg-3 our-license-img-container">
                    <img src={`${photo_url}/about_us/license/sijil_pendaftaran.jpg`} alt="sijil pendaftaran" />
                </div>
                <div className="col-md-6 col-lg-3 our-license-img-container">
                    <img src={`${photo_url}/about_us/license/eq_sammi.jpg`} alt="EQ certificate" />
                </div>
            </div>
        </div>
        </>
    )
}
export default OurLicense;