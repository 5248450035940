import tnc from "./terms_and_conditions.pdf";
import pp from "./privacy_policy.pdf";
import { useTranslation } from "react-i18next";
import { getShandeAddressWithHref, getShandeContactWithHref1, getShandeContactWithHref2, getShandeEmailWithHref, getStaticStaffNameAndNumber } from "../helpers/dataHelper";
function Footer() {
    const {t} = useTranslation();
    const shandeFacebook = process.env.REACT_APP_SHANDE_FACEBOOK;
    const shandeInstagram = process.env.REACT_APP_SHANDE_INSTAGRAM;
    const shandeSSM = process.env.REACT_APP_SHANDE_SSM;
    const marketingData = getStaticStaffNameAndNumber("Marketing")
    const today = new Date();
    const currentYear = today.getFullYear();
    return (
        <footer class="text-white bg-dark mt-5 my-footer">
            <div class="container py-4 py-lg-5 footer">
                <div class="row justify-content-center">
                    <div class="col-sm-12 col-lg-3 text-center text-lg-start d-flex flex-column item">
                        <div class="fw-bold d-flex flex-column flex-lg-row align-items-center mb-2">
                            <span class="bs-icon-sm bs-icon-rounded bs-icon-primary d-flex justify-content-center align-items-center bs-icon me-2 mb-2">
                                <img src = "assets/img/logo-icon.png" alt = "logo"/>
                            </span>
                            <span>{t("shande")}</span>
                        </div>
                        <ul class="list-unstyled">
                            <li><i class="ion-bookmark me-1"></i><a class="" href="/">{t("home")}</a></li>
                            <li><i class="ion-bookmark me-1"></i><a class="" href="/news">{t("news")}</a></li>
                            <li><i class="ion-bookmark me-1"></i><a class="" href="/events">{t("events")}</a></li>
                            <li><i class="ion-bookmark me-1"></i><a class="" href="/contactUs">{t("contactUs")}</a></li>
                            <li><i class="ion-bookmark me-1"></i><a class="" href="/faq">{t("faq")}</a></li>
                        </ul>

                        <h3 class="fs-6">{t("followUs")}</h3>
                        {/* facebook */}
                        <a href = {shandeFacebook} className="fb-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" class="bi bi-facebook">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                            </svg>
                            <span className="ms-2">Facebook</span>
                        </a>
                        {/* instagram */}
                        <a href = {shandeInstagram} className="ig-icon mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                            </svg>
                            <span className="ms-2">Instagram</span>
                        </a>
                    </div>
                    <div class="col-sm-3 text-center text-lg-start d-flex flex-column item">
                        <h3 class="fs-6">{t("supportUs")}</h3>
                        <ul class="list-unstyled">
                            <li><i class="ion-heart me-1"></i><a class="" href="/donationDetails">{t("donationDetails")}</a></li>
                            <li><i class="ion-heart me-1"></i><a class="" href="/sponsorshipPlan">{t("sponsorshipPlan")}</a></li>
                            <li><i class="ion-heart me-1"></i><a class="" href="/lovelyMealPlan">{t("lovelyMealPlan")}</a></li>
                            <li><i class="ion-heart me-1"></i><a class="" href="/charitySales">{t("charitySales")}</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-3 text-center text-lg-start d-flex flex-column item">
                        <h3 class="fs-6">{t("aboutShande")}</h3>
                        <ul class="list-unstyled">
                            <li><i class="ion-ios-book me-1"></i><a class="" href="/aboutUs">{t("aboutUs")}</a></li>
                            <li><i class="ion-ios-book me-1"></i><a class="" href="/ourOrganization">{t("ourOrganization")}</a></li>
                            <li><i class="ion-ios-book me-1"></i><a class="" href="/career">{t("career")}</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-12 col-lg-3 text-center text-lg-start d-flex flex-column align-items-center align-items-lg-start order-lg-last item social">
                        <h3 class="fs-6">{t("contactUs")}</h3>
                        <ul class="list-unstyled">
                            <li><i class="ion-ios-telephone"></i> Tel: {getShandeContactWithHref1()} / {getShandeContactWithHref2()}</li>
                            <li><i class="ion-android-mail"></i> Email: {getShandeEmailWithHref()}</li>
                            {
                                marketingData && marketingData.map((item) => (
                                    <li><i class="ion-social-whatsapp me-1"></i> 
                                    WhatsApp: <a href={`https://api.whatsapp.com/send?phone=6${item.contact}`}>{item.name} ({item.contact})</a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-lg-4 col-12 order-lg-last">
                        <div class="d-flex justify-content-lg-end justify-content-center">
                            <div>
                                <a href={tnc} target="_blank" class="tnc">Terms &amp; Conditions</a>
                                <a href={pp} target="_blank" class="tnc">Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-12">
                        <p class="text-muted mb-0 copyright">Copyright © Pertubuhan Kebajikan Shande Johor Bahru Johor 2021-{currentYear}. ({shandeSSM})</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;