import React, { useState, useEffect } from 'react';
import "./ProductSlideShow.css"
import { SplitBRString, getRandomStaffData } from '../helpers/dataHelper';
import { selectCharitySalesData } from '../helpers/StaticData/charitySalesData';
import { useTranslation } from 'react-i18next';

const ProductSlideShow = () => {
    const products = selectCharitySalesData();
    const redirectToRandomWhatsApp = (itemName) => {
        const randomContact = getRandomStaffData("Contact", "Marketing");
        const message = `你好，我对义卖物品感兴趣 (${itemName})`;
        const whatsappURL = `https://api.whatsapp.com/send?phone=6${randomContact}&text=${message}`;
        return whatsappURL;
        // window.location.href = whatsappURL;
    }
    const {t} = useTranslation();
    const [currentProduct, setCurrentProduct] = useState(0);
    const [isPaused, setIsPaused] = useState(false); // Add a state for pause

    const handleMouseEnter = () => {
        setIsPaused(true); // Pause the slideshow on mouse enter
    };
  
    const handleMouseLeave = () => {
        setIsPaused(false); // Resume the slideshow on mouse leave
    };

    const goToProduct = (index) => {
        setCurrentProduct(index);
    };
    
    const goToNextProduct = () => {
        const nextProduct = (currentProduct + 1) % products.length;
        setCurrentProduct(nextProduct);
    };
    
    const goToPrevProduct = () => {
        const prevProduct = (currentProduct - 1 + products.length) % products.length;
        setCurrentProduct(prevProduct);
    };
    
    useEffect(() => {
        if (!isPaused) {
          const sliderInterval = setInterval(goToNextProduct, 4000);
    
          return () => {
            clearInterval(sliderInterval);
          };
        }
      }, [currentProduct, isPaused]);
    
    const product = products[currentProduct];
    
    return (
        <div className="slider-container mt-4 pt-5 pb-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
            <div className="row">
                <div className="col-md-6">
                    <div className="product-image text-center">
                        <img src={product.image} alt={product.name} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="product-details">
                        <h2>{product.name}</h2>
                        <br />
                        <h6>{SplitBRString(product.description)}</h6>
                    <div className="text-center whatsapp-container">
                        <p className="price">{t("price")}: {product.price}</p>
                        <a
                        href={redirectToRandomWhatsApp(product.name)}
                        // onClick={()=>redirectToRandomWhatsApp(product.name)}
                        className="whatsapp-button flex-end"
                        target='_blank'
                        rel='noopener noreferrer'
                        >
                        {t("contactViaWhatsapp")}
                        </a>
                    </div>
                    </div>
                </div>
            </div>
            <div className="row pt-5">
                <div className="slider-navigation">
                    <button className="nav-button slider-prev" onClick={goToPrevProduct}>
                    &lt;
                    </button>
                    <div className="dots">
                    {products.map((_, index) => (
                        <span
                        key={index}
                        className={`dot ${index === currentProduct ? 'active' : ''}`}
                        onClick={() => goToProduct(index)}
                        />
                    ))}
                    </div>
                    <button className="nav-button slider-next" onClick={goToNextProduct}>
                    &gt;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProductSlideShow;
    