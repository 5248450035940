import React from "react";
import WhoAreWe from "../components/WhoAreWe";
import HomepageNews from "../components/HomepageNews";
import ThreeImagesInRow from "../components/ThreeImagesInRow";
import ImageSlideShow from "../components/ImageSlideShow";
import { useSpring, animated, useScroll, useInView, Transition } from '@react-spring/web'
import OurData from "../components/OurData";
import OurReview from "../components/OurReview";
import OurReviewV2 from "../components/OurReviewV2";

function Homepage() {
    document.title = 'Home | Shan De'; 
    const [refService, inViewService] = useInView()
    const animatedDisplay1 = useSpring({
        opacity: inViewService ? 1 : 0,
        config: { duration: 1000 }
    })
    const [refSpecialSkill, inViewSpecialSkill] = useInView()
    const animatedDisplay2 = useSpring({
        opacity: inViewSpecialSkill ? 1 : 0,
        config: { duration: 2000 }
    })
    return (
        <>
        <ImageSlideShow />

        <animated.section ref={refService} style={animatedDisplay1}>
        <ThreeImagesInRow />
        </animated.section>

        <animated.section ref={refSpecialSkill} style={animatedDisplay2}>
        <HomepageNews />
        </animated.section>

        <OurData />

        {/* <OurReview /> */}

        <OurReviewV2 />

        <WhoAreWe />
        </>
    )
}
export default Homepage;