import React, { useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import CareerDetails from "./CareerDetails";

function ModalCentered(props) {
    const { t } = useTranslation()
    const modalData = props?.modalData;
    
    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={true}
        onHide={()=>props.onHide(false)}
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {props?.title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CareerDetails data = {modalData} />
        </Modal.Body>
        {/* <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
        </Modal>
    )
}
export default ModalCentered;