import { useLocation } from "react-router";
import TitleHeading from "../components/TitleHeading";
import { getLanguage } from "../helpers/storageHelper";
import { useTranslation } from "react-i18next";
import ImageWithDescription from "../components/ImageWithDescription";
import MultilineContent from "../components/MultilineContent";
import { useSearchParams } from "react-router-dom";
import React from "react";
import { getEventsById } from "../helpers/Api/apiHelper";
import LoadingScreen from "../components/LoadingScreen";

function EventsDetails() {
    const {state} = useLocation();
    const language = getLanguage();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')
    const {t} = useTranslation();
    const [eventsData, setEventsData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const data = state ?? eventsData;
    console.log(data)
    React.useEffect(() => {
        if(!state && id) {
            const getEvents = async (id) => {
                const response = await getEventsById(id)
                if(response) {
                    console.log(response)
                    if(response.success) {
                        setEventsData(response.success);
                        setLoading(false);
                    }
                    else {
                        console.log(response.error);
                        setEventsData(null);
                        setLoading(false);
                    }
                }
                else {
                    setEventsData(null);
                    setLoading(false);
                }
            }
            getEvents(id);
        }
        else {
            setLoading(false)
        }
    },[])
    
    if(loading) return (<LoadingScreen />)

    if(data) {
        return (
            <section class="portfolio-block project p-0">
                <TitleHeading
                btnBackHref = "/events"
                title = {language == 1 ? data?.title : data?.enTitle}
                className = "text-center pt-4"
                />
                <div class="container">
                    <div class="img-container text-center mb-4">
                    <img src={data?.mainImageUrl} alt={data?.enTitle} class="images img-fluid mh-600" />
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 offset-md-1 info">
                            <h3>{t("description")}</h3>
                            <p>{MultilineContent(language == 1 ? data?.content : data?.enContent)}</p>
                        </div>
                        <div class="col-12 col-md-3 offset-md-1 meta">
                            <div class="tags">
                                {/* <span class="meta-heading">Tags</span>
                                <a href="#">Resource</a>
                                <a href="#">Project</a> */}
                                <span class="meta-heading">{t("date")}</span>
                                <span>{data?.date}</span>
                            </div>
                        </div>
                    </div>
                            
                    {
                        data?.detailResponses && data?.detailResponses.map((item, index) => (
                            <div class="row pt-5">
                            <ImageWithDescription
                            imgFlexible = {true}
                            img = {item?.photoUrl}
                            title = {language == 1 ? item?.title : item?.enTitle}
                            description = {language == 1 ? item?.content : item?.enContent}
                            reverse = {index % 2 == 0 ? false : true}
                            />
                            </div>
                        ))
                    }
                    <div class="more-projects">
                        <h3 class="text-center">{t("moreImage")}</h3>
                        <div class="row gallery">
                            {
                                data?.photoUrls && data?.photoUrls.map((item) => (
                                    <div class="col-md-4 col-lg-3 item">
                                        <div class="item m-0">
                                            <img class="img-fluid scale-on-hover" src={item} alt="Other Images" />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default EventsDetails;