import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './helpers/ScrollToTop';
import ChatIcon from './components/ChatIcon';
import Header from './nav/header';
import Footer from './nav/footer';
import Homepage from './pages/Homepage';
import LovelyMealPlan from './pages/LovelyMealPlan';
import News from './pages/News';
import NewsDetails from './pages/NewsDetails';
import Events from './pages/Events';
import SponsorshipPlan from './pages/SponsorshipPlan';
import DonationDetails from './pages/DonationDetails';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguage } from './helpers/storageHelper';
import FAQ from './pages/Faq';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import OurOrganization from './pages/OurOrganization';
import Career from './pages/Career';
import CharitySales from './pages/CharitySales';
import EventsDetails from './pages/EventsDetails';
import TngIcon from './components/TngIcon';

i18n
.use(initReactI18next)
.init({
  lng: getLanguage() ?? 1,
  resources: {
    1: {
      translation: require('./locales/cn.json')
    },
    2: {
      translation: require('./locales/en.json')
    }
  }
});

function App() {

  return (
    <Router>
      <Header />
      <ScrollToTop>
        <TngIcon />
        <ChatIcon />
        <Routes>
          <Route exact path='/' element={ <Homepage /> } />
          <Route exact path='/news' element={ <News /> } />
          <Route exact path='/newsDetails' element={ <NewsDetails /> } />
          <Route exact path='/lovelyMealPlan' element={ <LovelyMealPlan /> } />
          <Route exact path='/events' element={ <Events /> } />
          <Route exact path='/eventsDetails' element={ <EventsDetails /> } />
          <Route exact path='/sponsorshipPlan' element={ <SponsorshipPlan /> } />
          <Route exact path='/donationDetails' element={ <DonationDetails /> } />
          <Route exact path='/faq' element={ <FAQ /> } />
          <Route exact path='/contactUs' element={ <ContactUs /> } />
          <Route exact path='/aboutUs' element={ <AboutUs /> } />
          <Route exact path='/ourOrganization' element={ <OurOrganization /> } />
          <Route exact path='/career' element={ <Career /> } />
          <Route exact path='/charitySales' element={ <CharitySales /> } />
        </Routes>
      </ScrollToTop>
      <Footer />
    </Router>
  );
}

export default App;
