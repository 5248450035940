import { getLanguage } from "../storageHelper";
export function getLovelyMealPlanSteps() {
    const photo_url = process.env.REACT_APP_SHANDE_PHOTO_URL;
    let steps = null;
    const language = getLanguage();
    if(language == 1) {
        steps = [
            {
                img: photo_url + "lovely_meal_plan/step1.jpeg",
                title: "步骤 1",
                description: "咨询社工了解爱心膳食"
            },
            {
                img: photo_url + "lovely_meal_plan/step2.jpeg",
                title: "步骤 2",
                description: "捐赠善款，注明支持“爱心膳食计划”"
            },
            {
                img: photo_url + "lovely_meal_plan/step3.jpeg",
                title: "步骤 3",
                description: "筹集大约100份便当的资金"
            },
            {
                img: photo_url + "lovely_meal_plan/step4.jpeg",
                title: "步骤 4",
                description: "有执照的中心义工会联系需要帮助的弱势群体"
            },
            {
                img: photo_url + "lovely_meal_plan/step5.jpeg",
                title: "步骤 5",
                description: "义工确认数量，时间和地点"
            },
            {
                img: photo_url + "lovely_meal_plan/step6.jpeg",
                title: "步骤 6",
                description: "中心社工和孩子们一起准备和打包饭盒"
            },
            {
                img: photo_url + "lovely_meal_plan/step7.jpeg",
                title: "步骤 7",
                description: "义工接取100份饭盒到指定地点"
            },
            {
                img: photo_url + "lovely_meal_plan/step8.jpeg",
                title: "步骤 8",
                description: "义工团队派发便当，并拍照证明饭盒已成功帮助弱势群体"
            },
            {
                img: photo_url + "lovely_meal_plan/step9.jpeg",
                title: "步骤 9",
                description: "社工发送照片给参与爱心膳食计划的善心人士，证明饭盒已成功帮助弱势群体饱餐一顿"
            },
        ]
    }
    else {
        steps = [
            {
                "img": photo_url + "lovely_meal_plan/step1.jpeg",
                "title": "Step 1",
                "description": "Consult social worker to understand the Lovely Meal Plan"
            },
            {
                "img": photo_url + "lovely_meal_plan/step2.jpeg",
                "title": "Step 2",
                "description": "Donate funds, specify support for the 'Lovely Meal Plan'"
            },
            {
                "img": photo_url + "lovely_meal_plan/step3.jpeg",
                "title": "Step 3",
                "description": "Raise funds for approximately 100 meal boxes"
            },
            {
                "img": photo_url + "lovely_meal_plan/step4.jpeg",
                "title": "Step 4",
                "description": "Licensed center volunteers will contact vulnerable groups in need"
            },
            {
                "img": photo_url + "lovely_meal_plan/step5.jpeg",
                "title": "Step 5",
                "description": "Volunteers confirm quantity, timing, and location"
            },
            {
                "img": photo_url + "lovely_meal_plan/step6.jpeg",
                "title": "Step 6",
                "description": "Center's social workers and children prepare and pack meal boxes together"
            },
            {
                "img": photo_url + "lovely_meal_plan/step7.jpeg",
                "title": "Step 7",
                "description": "Volunteers collect 100 meal boxes at the designated location"
            },
            {
                "img": photo_url + "lovely_meal_plan/step8.jpeg",
                "title": "Step 8",
                "description": "Volunteer team distributes the meals and takes photos to confirm successful assistance to vulnerable groups"
            },
            {
                "img": photo_url + "lovely_meal_plan/step9.jpeg",
                "title": "Step 9",
                "description": "Social worker sends photos to kind-hearted participants of the Lovely Meal Plan, proving that the meal boxes have successfully helped the vulnerable groups enjoy a meal"
            }
        ]        
    }
    return steps;
}