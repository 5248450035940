import React from "react";
import ModalCentered from "./ModalCentered";
import { getCareerData } from "../helpers/StaticData/careerData";
import { useTranslation } from "react-i18next";
function CareerJobPosition(props) {
    const [modalData, setModalData] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const {t} = useTranslation();
    const careerData = getCareerData();
    const showDescriptionModal = (item) => {
        setModalData(item);
        setShowModal(true);
    }
    // if(showModal) return (
    //     <ModalCentered
    //     onHide = {setShowModal}
    //     title = {modalData?.title}
    //     modalData = {modalData}
    //     />
    // )
    return (
        
        <>
        {
            showModal ?
            <ModalCentered
            onHide = {setShowModal}
            title = {modalData?.title}
            modalData = {modalData}
            />
            : ""
        }
        <div class="row">
            {
                careerData && careerData.map((item, index) => (
                    item.type == props?.type ?
                        <div class="col-md-6 col-lg-3">
                            <div class="staff-container staff-container-mobile">
                                <img class="img_career" src="assets/img/career/career.png" alt="career" />
                                <div class="staff-body p-0 mt-0 h-100">
                                    <h5 class="staff-title text-center">
                                        {item.title}
                                    </h5>
                                    <a class="position-description mb-3" onClick={()=>showDescriptionModal(item)}>
                                        {t("positionDescription")}
                                    </a>
                                    <a class="btn btn-primary btn-black w-100" target="_blank"
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSeyxJlBoMgS6pFgm_PTLkPD9AQsnO6dPj_SQupYtCg0YCpFuA/viewform?usp=sf_link">{t("applyNow")}</a>
                                </div>
                            </div>
                        </div>
                    : ""
                ))
            }
        </div>
        </>
    )
}
export default CareerJobPosition;