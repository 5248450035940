import { useTranslation } from "react-i18next";
import ImageWithDescription from "../components/ImageWithDescription";
import NineImagesWithDescription from "../components/NineImagesWithDescription";
import TitleHeading from "../components/TitleHeading";
import { getLovelyMealPlanSteps } from "../helpers/StaticData/lovelyMealPlanData";
import ButtonFullRow from "../components/ButtonFullRow";
import { getRandomStaffData } from "../helpers/dataHelper";
import { getLanguage } from "../helpers/storageHelper";

function LovelyMealPlan() {
    document.title = 'Lovely Meal Plan | Shan De'; 
    const {t} = useTranslation();
    const data = getLovelyMealPlanSteps();
    const language = getLanguage();
    const redirectToRandomWhatsApp = (clickedButton) => {
        const randomContact = getRandomStaffData("Contact", "Marketing");
        const getMessage = (button = 1) => {
            if(button == 1) {
                if(language == 1) {
                    return `你好，我想捐赠善款`;
                }
                else {
                    return "Hello, I would like to donate";
                }
            }
            else {
                if(language == 1) {
                    return `你好，我想了解爱心膳食计划`;
                }
                else {
                    return "Hello, I would like to know more about Lovely Meal Plan";
                }
            }
        }
        const message = getMessage(clickedButton);
        const whatsappURL = `https://api.whatsapp.com/send?phone=6${randomContact}&text=${message}`;
        return whatsappURL;
        // window.location.href = whatsappURL;
    }
    const redirectToTouchNGo = () => {
        const touchNGoURL = process.env.REACT_APP_TOUCH_N_GO_URL;
        return touchNGoURL;
    }
    const button1 = {
        title: t("donateUs"),
        path: redirectToRandomWhatsApp(1)
    }
    const button2 = {
        title: t("joinLovelyMealPlan"),
        path: redirectToRandomWhatsApp(2)
    }
    return (
        <>
        <div className="container">
            <TitleHeading
            title = {t("lovelyMealPlan")}
            className = "pt-5"
            />
            <ImageWithDescription 
            img = "assets/img/lovely_meal_plan/lovely_lanscape.jpeg"
            title = {t("lovelyMealPlanTitle")}
            description = {t("lovelyMealPlanDescription")}
            />

            <NineImagesWithDescription 
            data = {data}
            title = {t("lovelyMealPlan")}
            />

        </div>
        <ButtonFullRow 
        button1 = {button1}
        button2 = {button2}
        />
        </>
    )
}
export default LovelyMealPlan;