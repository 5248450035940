import { getShandeExplanation } from "../helpers/StaticData/aboutUsData";


function ShandeWordsAndImages() {
    const data = getShandeExplanation();
    return (
        <div>
            {
                data && data.map((item, index) => (
                <div className="row pt-2 pb-5">
                    <div className={`col-lg-6 p-3 d-flex flex-column justify-content-center ${index % 2 === 0 ? "order-lg-2" : ""}`}>
                        <h1>{item?.title.substring(0, 1)}<span className="font-small">{item?.title.substring(1)}</span></h1>
                        <div className="pb-4">
                        {item.text.map((text, textIndex) => (
                            <div>
                            <span class='number mb-2'>
                                    {textIndex + 1}
                            </span>
                            <h6 className="word-description">{text}</h6>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className={`col-lg-6 ps-3 pe-5`}>
                        <img className="img-fluid br-25" src={item?.img} alt={item?.title}></img>
                    </div>
                </div>
                ))
            }
        </div>
    )
}
export default ShandeWordsAndImages;