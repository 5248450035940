import { useTranslation } from "react-i18next";
import ImageAndDetails from "../components/ImageAndDetails";
import ImageWithDescription from "../components/ImageWithDescription";
import TitleHeading from "../components/TitleHeading";
import { StaticChildData } from "../helpers/StaticData/childrenData";

function SponsorshipPlan() {
    document.title = 'Sponsorship Plan | Shan De'; 
    const {t} = useTranslation();
    const childDetails = StaticChildData();

    return (
        <div className="container">
            <TitleHeading 
            title = {t("sponsorshipPlan")}
            className = "pt-5"
            />

            <ImageWithDescription
            img = "assets/img/childPic/sponsor.png"
            title = {t("sponsorTitle")}
            description = {t("sponsorDescription")}
            />
            <ImageAndDetails 
            data = {childDetails}
            />
        </div>

    )
}
export default SponsorshipPlan;