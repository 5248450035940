import { getStaticStaffNameAndNumber } from "../dataHelper";
import { getLanguage } from "../storageHelper";

export function getCareerData() {
    const language =  getLanguage() ?? 1;
    let careerData = null;
    if (language == 1) {
      careerData = [
        {
          type: "Full Time",
          title: "行销助理",
          education: "至少SPM",
          work_experience: "无需工作经验，我们提供在职培训",
          job_scope: [
            "招待上门的访客，确保提供给客户的信息准确、清晰",
            "回复面子书、电话信息",
            "管理客户常见问题，定期回答客户拨打热线电话的客户服务咨询"
          ],
          job_requirement: [
            "会基础的华文，马来文，英文读写能力",
            "肯做，肯学，应变能力强, 态度良好，有耐心",
            "面对陌生人不胆怯，并能流利沟通",
            "能够遵守上司的指示并及时完成任务",
            "需懂得电脑的基本操作 (Power Point, Excel, Word & Microsoft Applications)"
          ],
          we_provide: [
            "六天工作日",
            "EPF & SOCSO",
            "每日膳食",
            "医疗补贴",
            "年终花红"
          ],
          contact: getStaticStaffNameAndNumber("Vice President")
        },
        {
          type: "Full Time",
          title: "老师",
          education: "至少SPM",
          work_experience: "有经验者优先",
          job_scope: [
            "教导孩子学校课业，补习课外知识",
            "额外教导几位学习能力较弱的孩子",
            "教导孩子礼貌，尊重等额外知识"
          ],
          job_requirement: [
            "会基础的华文，马来文，英文读写能力",
            "负责任，应变能力强, 态度良好，有耐心",
            "华文，马来文，英文，数学中，至少一科成绩优异",
            "能够遵守上司的指示并及时完成任务",
            "需懂得电脑的基本操作 (Power Point, Excel, Word & Microsoft Applications)"
          ],
          we_provide: [
            "六天工作日",
            "EPF & SOCSO",
            "每日膳食",
            "医疗补贴",
            "年终花红"
          ],
          contact: getStaticStaffNameAndNumber("Vice President")
        },
        {
          type: "Full Time",
          title: "书记",
          education: "至少SPM",
          work_experience: "无需工作经验，我们提供在职培训",
          job_scope: [
            "处理文书类工作",
            "处理学校方面的问题",
            "处理政府部门的一些文书工作"
          ],
          job_requirement: [
            "会基础的华文，马来文，英文读写能力",
            "负责任，应变能力强, 态度良好，有耐心",
            "马来文沟通和书写能力优异者优先",
            "能够遵守上司的指示并及时完成任务",
            "需懂得电脑的基本操作 (Power Point, Excel, Word & Microsoft Applications)"
          ],
          we_provide: [
            "六天工作日",
            "EPF & SOCSO",
            "每日膳食",
            "医疗补贴",
            "年终花红"
          ],
          contact: getStaticStaffNameAndNumber("Vice President")
        },
        {
          type: "Full Time",
          title: "保姆",
          education: "无要求",
          work_experience: "有经验者优先",
          job_scope: [
            "照顾数位7岁以下的孩子 (主要为幼儿园以下)",
            "照顾他们的起居饮食",
            "教导他们说话，基本常识（如何上厕所，如何吃饭等等）"
          ],
          job_requirement: [
            "会基础的华文，马来文，英文读写能力",
            "负责任，应变能力强, 态度良好，有耐心",
            "能够在中心住宿者优先",
            "能够遵守上司的指示并及时完成任务",
          ],
          we_provide: [
            "六天工作日",
            "EPF & SOCSO",
            "每日膳食",
            "医疗补贴",
            "年终花红"
          ],
          contact: getStaticStaffNameAndNumber("Vice President")
        },
        {
          type: "Full Time",
          title: "看护",
          education: "无要求",
          work_experience: "有经验者优先",
          job_scope: [
            "照顾12岁以下的孩子 (主要为小学)",
            "照顾他们的起居饮食",
            "教导他们礼貌，自律，基本知识"
          ],
          job_requirement: [
            "会基础的华文，马来文，英文读写能力",
            "负责任，应变能力强, 态度良好，有耐心",
            "能够遵守上司的指示并及时完成任务",
          ],
          we_provide: [
            "六天工作日",
            "EPF & SOCSO",
            "每日膳食",
            "医疗补贴",
            "年终花红"
          ],
          contact: getStaticStaffNameAndNumber("Vice President")
        },
        {
          type: "Volunteer",
          title: "老师",
          education: "至少SPM",
          work_experience: "无需工作经验",
          job_scope: [
            "教导孩子学校课业，补习课外知识",
            "额外教导几位学习能力较弱的孩子",
            "教导孩子礼貌，尊重等额外知识"
          ],
          job_requirement: [
            "会基础的华文，马来文，英文读写能力",
            "负责任，应变能力强, 态度良好，有耐心",
            "华文，马来文，英文，数学中，至少一科成绩优异",
            "能够与其他同事配合",
            "每星期固定一天者优先",
          ],
          contact: getStaticStaffNameAndNumber("Vice President")
        },
      ]
    }
    else {
      careerData = [
        {
          "type": "Full Time",
          "title": "Marketing Assistant",
          "education": "At least SPM",
          "work_experience": "No work experience required, on-the-job training provided",
          "job_scope": [
            "Welcome and assist visitors, ensuring accurate and clear information to clients",
            "Respond to Facebook and phone messages",
            "Manage common customer queries, provide regular customer service support via hotline"
          ],
          "job_requirement": [
            "Basic proficiency in written and spoken Chinese, Malay, and English",
            "Willingness to work, learn, adapt, good attitude, and patience",
            "Confident in interacting with strangers and effective communication skills",
            "Able to follow instructions from superiors and complete tasks promptly",
            "Basic computer skills (PowerPoint, Excel, Word & Microsoft Applications)"
          ],
          "we_provide": [
            "Six working days",
            "EPF & SOCSO",
            "Daily meals",
            "Medical allowance",
            "Year-end bonus"
          ],
          "contact": getStaticStaffNameAndNumber("Vice President")
        },
        {
          "type": "Full Time",
          "title": "Teacher",
          "education": "At least SPM",
          "work_experience": "Experience preferred",
          "job_scope": [
            "Teach school subjects and provide additional tutoring",
            "Provide extra assistance to students with learning difficulties",
            "Teach children manners, respect, and additional knowledge"
          ],
          "job_requirement": [
            "Basic proficiency in written and spoken Chinese, Malay, and English",
            "Responsible, adaptable, good attitude, and patience",
            "Excellent performance in at least one subject among Chinese, Malay, English, and Mathematics",
            "Able to follow instructions from superiors and complete tasks promptly",
            "Basic computer skills (PowerPoint, Excel, Word & Microsoft Applications)"
          ],
          "we_provide": [
            "Six working days",
            "EPF & SOCSO",
            "Daily meals",
            "Medical allowance",
            "Year-end bonus"
          ],
          "contact": getStaticStaffNameAndNumber("Vice President")
        },
        {
          "type": "Full Time",
          "title": "Secretary",
          "education": "At least SPM",
          "work_experience": "No work experience required, on-the-job training provided",
          "job_scope": [
            "Handle paperwork",
            "Address school-related issues",
            "Manage documentation for government departments"
          ],
          "job_requirement": [
            "Basic proficiency in written and spoken Chinese, Malay, and English",
            "Responsible, adaptable, good attitude, and patience",
            "Proficiency in Malay communication and writing is preferred",
            "Able to follow instructions from superiors and complete tasks promptly",
            "Basic computer skills (PowerPoint, Excel, Word & Microsoft Applications)"
          ],
          "we_provide": [
            "Six working days",
            "EPF & SOCSO",
            "Daily meals",
            "Medical allowance",
            "Year-end bonus"
          ],
          "contact": getStaticStaffNameAndNumber("Vice President")
        },
        {
          "type": "Full Time",
          "title": "Nanny",
          "education": "No specific requirement",
          "work_experience": "Experience preferred",
          "job_scope": [
            "Care for children under 7 years old (mainly preschoolers)",
            "Attend to their daily needs including meals",
            "Teach basic skills like speaking, toilet training, eating, etc."
          ],
          "job_requirement": [
            "Basic proficiency in written and spoken Chinese, Malay, and English",
            "Responsible, adaptable, good attitude, and patience",
            "Preference for candidates willing to reside on-site",
            "Able to follow instructions from superiors and complete tasks promptly"
          ],
          "we_provide": [
            "Six working days",
            "EPF & SOCSO",
            "Daily meals",
            "Medical allowance",
            "Year-end bonus"
          ],
          "contact": getStaticStaffNameAndNumber("Vice President")
        },
        {
          "type": "Full Time",
          "title": "Caretaker",
          "education": "No specific requirement",
          "work_experience": "Experience preferred",
          "job_scope": [
            "Care for children under 12 years old (mainly elementary school)",
            "Attend to their daily needs including meals",
            "Teach manners, self-discipline, and basic knowledge"
          ],
          "job_requirement": [
            "Basic proficiency in written and spoken Chinese, Malay, and English",
            "Responsible, adaptable, good attitude, and patience",
            "Able to follow instructions from superiors and complete tasks promptly"
          ],
          "we_provide": [
            "Six working days",
            "EPF & SOCSO",
            "Daily meals",
            "Medical allowance",
            "Year-end bonus"
          ],
          "contact": getStaticStaffNameAndNumber("Vice President")
        },
        {
          "type": "Volunteer",
          "title": "Teacher",
          "education": "At least SPM",
          "work_experience": "No work experience required",
          "job_scope": [
            "Teach school subjects and provide additional tutoring",
            "Provide extra assistance to students with learning difficulties",
            "Teach children manners, respect, and additional knowledge"
          ],
          "job_requirement": [
            "Basic proficiency in written and spoken Chinese, Malay, and English",
            "Responsible, adaptable, good attitude, and patience",
            "Excellent performance in at least one subject among Chinese, Malay, English, and Mathematics",
            "Ability to collaborate with colleagues",
            "Preferably available one fixed day per week",
          ],
          "contact": getStaticStaffNameAndNumber("Vice President")
        }
      ]      
    }
    return careerData
  }