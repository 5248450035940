function FacebookIframe(props) {
    const url = props?.url;

    if(url) {
        return (
            <iframe src={`https://www.facebook.com/plugins/post.php?href=${url}&show_text=true`}
            width="600" height="669" scrolling="no" frameborder="0" allowfullscreen="true" 
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
            </iframe>
        )
    }
    else {
        return (
            <div className="alert alert-danger">
                <h1>Not Found</h1>
            </div>
        )
    }
}
export default FacebookIframe;