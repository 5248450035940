import { useTranslation } from "react-i18next";
import ImageWithDescription from "./ImageWithDescription";
import { useSpring, animated, useScroll, useInView, Transition } from '@react-spring/web'
function WhoAreWe() {
    const {t} = useTranslation();
    const [refSlideUp, inViewSlideUp] = useInView()
    const animatedDisplay1 = useSpring({
        opacity: inViewSlideUp ? 1 : 0,
        transform: inViewSlideUp ? "translateY(0px)" : "translateY(100px)",
        config: { duration: 1000 }
    })
    return (
        <section class="portfolio-block website gradient">
            <div class="container">
                <div class="row align-items-center">
                <animated.section ref={refSlideUp} style={animatedDisplay1}>
                    
                    <ImageWithDescription
                    className = "font-white"
                    img = "assets/img/homepage/children_online.png"
                    title = {t("whoAreWeTitle")}
                    description = {t("whoAreWeDescription")}
                    reverse = {true}
                    />
                </animated.section>
                    {/* <div class="col-md-12 col-lg-5 offset-lg-1 text">
                        <h3>Website Project</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eget velit ultricies, feugiat est sed, efr nunc, vivamus vel accumsan dui. Quisque ac dolor cursus, volutpat nisl vel, porttitor eros.</p>
                    </div>
                    <div class="col-md-12 col-lg-5">
                        <div class="img-container">
                            <img class="mw-100" src="assets/img/homepage/children_online.jpg" alt="sponsorpic image" />
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}
export default WhoAreWe;