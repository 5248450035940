import TitleHeading from "./TitleHeading";

function NineImagesWithDescription(props) {
    const data = props?.data;
    const title = props?.title;

    if(data)
    return (
        <section class="portfolio-block projects compact-grid">
            <TitleHeading
            title = {title}
            className = "text-center pb-4"
            />
            <div class="row g-0">
                {
                    data && data.map(item => (
                        <div class="col-md-6 col-lg-4 item zoom-on-hover position-relative">
                            <a>
                                <img class="img-fluid image" src={item?.img} />
                                <span class="description">
                                    <span class="description-heading">{item?.title}</span>
                                    <span class="description-body">{item?.description}</span>
                                </span>
                            </a>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}
export default NineImagesWithDescription;