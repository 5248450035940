import React from "react";
import { getNewsPagination } from "../helpers/Api/apiHelper";
import TitleHeading from "./TitleHeading";
import MuiSkeleton from "./MuiSkeleton";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../helpers/storageHelper";
import { Link } from "react-router-dom";

function HomepageNews() {
    const [newsData, setNewsData] = React.useState(null);
    const {t} = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const language = getLanguage();

    const getNews = async (pageNo) => {
        const response = await getNewsPagination(pageNo)
        if(response) {
            if(response.success) {
                // get the first 3 array
                const firstThreeArray = response.success.data.slice(0,3);
                setNewsData(firstThreeArray);
                setLoading(false);
            }
            else {
                console.log(response.error);
                setNewsData(null);
                setLoading(false);
            }
        }
        else {
            setNewsData(null);
            setLoading(false);
        }
    }
    React.useEffect(() => {
        getNews(0);
    },[])

    if(loading) return (
        <div className="container">
            <TitleHeading 
            title = "News"
            className = "text-center pt-5"
            />
            <MuiSkeleton skeletonAmount = {3} />
        </div>
    )

    if(newsData)
    return (
        <section class="portfolio-block skills">
            <div class="container">
                <TitleHeading
                title = {t("news")}
                className = "text-center pb-4"
                />
                <div class="row">
                {
                    newsData && newsData.map(item => (
                        <div class="col-md-6 col-lg-4 zoom-on-hover">
                            <div class="item transition text-center news-image-container">
                                <img class="mw-100 mh-100 image" src={item?.mainImageUrl} alt={item?.enTitle} />
                            </div>
                            <div class="project-card-no-image">
                                <h3>{language == 1 ? item?.title : item?.enTitle}</h3>
                                <h4>{language == 1 ? item?.content : item?.enContent}</h4>
                                <Link class="btn btn-outline-primary btn-sm" to={`/newsDetails?id=${item?.id}`} state={item}>See More</Link>
                                <div class="tags"><p>{item?.date}</p></div>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        </section>
    )
}
export default HomepageNews;