import { useTranslation } from "react-i18next";
import CollapseData from "../components/CollapseData";
import TitleHeading from "../components/TitleHeading";
import { getFaqObject } from "../helpers/StaticData/faqData";

function FAQ() {
    document.title = 'Faq | Shan De'; 
    const faqData = getFaqObject();
    const {t} = useTranslation();
    return (
        <>
        <TitleHeading
        title = {t("faq")}
        className = "text-center pt-5"
        />
        <CollapseData
        data = {faqData}
        />
        </>
    )
}
export default FAQ;